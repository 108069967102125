import React, {useState} from 'react';
import { Box, Button, Card, CardContent, CardHeader, Container, TextField, Typography } from "@mui/material";
import { SecurityQuestionVerificationStyles } from './SecurityQuestionVerificationStyles';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { Controller, useForm,SubmitHandler } from "react-hook-form";
import { NavLink, useNavigate, useSearchParams,useLocation,createSearchParams } from 'react-router-dom';
import { loginAfterVerificaion,sendOtpOnEmail } from "../../resources/api-constants";
import Swal from "sweetalert2";
import { ROUTES } from "../../resources/routes-constants";
import { useAuth } from '../../resources/AuthProvider';

interface IFormInput {
    answer: string;
}

const SecurityQuestionVerification = () => {
    const [attemptLeft, setAttemptLeft] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const questionObj = searchParams.get('question') || '';
    const question = JSON.parse(questionObj)
    const userName = searchParams.get('userName') || '';
    const lat = searchParams.get('lat') || '';
    const lng = searchParams.get('lng') || '';
    const navigate = useNavigate();
    const location = useLocation();
    const { setToken} = useAuth();
    const from = location.state?.from?.pathname || ROUTES.DOCUMENT_MANAGEMENT;

    const { register, handleSubmit, formState: { errors }, setError, reset, control } = useForm<IFormInput>({
        defaultValues: {
            answer: ''
        }
    });

    const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
       
        const payload = {
            answer: data.answer,
            email: userName,
            latitude:lat,
            longitude:lng,
            //@ts-ignore
            question: question?.id
        }
      loginAfterVerificaion(payload)
       .then((res) => {
        setAttemptLeft(res?.data?.attempts_left)
        
        if(res?.data?.is_external_user){
            const params = {email:userName.toLowerCase(),lat:lat, lng: lng}
            navigate({
                pathname: `${ROUTES.MFA_VERIFICATION}`,
                //@ts-ignore
                search: `?${createSearchParams(params)}`,
            });
        }
        
        if(res?.data?.token){
            Swal.fire({
                text: 'Your account has been successfully verified.',
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              })
                localStorage.setItem('user',JSON.stringify(res?.data?.user))
                setToken(res.data.token)
                navigate(from, { replace: true });    
        }

        else if(res?.data?.is_account_locked){
            navigate(ROUTES.UNLOCK_ACCOUNT)
        }  

       })
       .catch((error) => Swal.fire({
        text: error.response.data.error,
        icon: "error",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
      }))
      };

      const confirmCancel = () => {
        Swal.fire({
            text: "Are you sure you want to cancel Account Verification?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer: 'custom-html-container',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(ROUTES.LOGIN_ROUTE, { replace: true })
            }

        });
    }

    const sendOTP = () => {
     const payload = {
            email:userName,
        }

        sendOtpOnEmail(payload)
         .then(res => {
            console.log("🚀 ~ sendOTP ~ res:", res)
            Swal.fire({
                text: 'An OTP has been sent to your registered Email address.',
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              }).then(result => {
                const params = {email:userName,lat:lat, lng: lng }
                navigate({
                    pathname: `${ROUTES.EMAIL_OTP}`,
                    search: `?${createSearchParams(params)}`,
                  });
              })
         })
         .catch(error => {
            Swal.fire({
                text: error.response.data.error,
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              })
         })

        // Swal.fire({
        //     text: 'An OTP has been sent to your registered Email address.',
        //     icon: "success",
        //     customClass: {
        //       confirmButton: 'custom-confirm-button',
        //       cancelButton: 'custom-cancel-button',
        //       htmlContainer : 'custom-html-container',
        //      }
        //   }).then(result => {
        //     const params = {email:userName}
        //     navigate({
        //         pathname: `${ROUTES.EMAIL_OTP}`,
        //         search: `?${createSearchParams(params)}`,
        //       });
        //         // navigate(ROUTES.EMAIL_OTP, { replace: true })
        //   })
        
    }

    return (
        <>
            <Container component="main" maxWidth="sm">
                <Box textAlign={"center"} mt={5} mx={5}>
                    <Box
                        component="img"
                        alt="NTCA Logo"
                        src={NtcaLogo}
                        mb={5}
                    />

                    <Card sx={SecurityQuestionVerificationStyles.cardContainer}>
                        <CardHeader title={
                            <>
                                <Typography component="h1" variant="h5" fontWeight={"bold"} gutterBottom sx={{
                                    color: '#475260'
                                }}>
                                    Security Question Verification
                                </Typography>
                                <Typography fontWeight={"400"} mt={2} gutterBottom sx={{
                                    color: '#475260'
                                }}>
                                    Please answer the security question to <br /> recover your account
                                </Typography>
                            </>
                        } />

                        <CardContent sx={{ textAlign: 'left', mx: 2, mt: 3 }}>

                            <Box
                                component="form"
                                onSubmit={handleSubmit(onSubmit)}
                                noValidate
                            >
                                <Typography fontWeight={"bold"} gutterBottom>
                                     
                                    {
                                        //@ts-ignore 
                                    question?.question
                                    }
                                </Typography>

                                <Controller
                                    name={`answer`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id={`security-question-answer`}
                                            label="Type your answer here..."
                                            {...register(`answer`, {
                                                required: 'Please provide an answer to the required question.',
                                                pattern: {
                                                    value: /^(?!\s*$).+/,
                                                    message: 'The field cannot contain only spaces.',
                                                }
                                            })}
                                            error={!!errors?.answer}
                                            helperText={errors?.answer?.message}
                                            {...field}
                                        />
                                    )}
                                />

                                <Box mt={2} sx={{textAlign: 'center'}}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={SecurityQuestionVerificationStyles.submitButton}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="text"
                                        fullWidth
                                        sx={SecurityQuestionVerificationStyles.cancelButton}
                                        onClick={confirmCancel}
                                        disableRipple
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                {/* <NavLink to={ROUTES.EMAIL_OTP} className='custom-otpLink'> */}
                                    <Typography 
                                        onClick={sendOTP}
                                        mt={1} ml={3} 
                                        sx={{width:'25%', marginLeft:'80%' ,cursor:'pointer',color: '#0073ae', fontSize:'14px', textdecoration:'none', '&:hover': {color: '#FDB913'}}}>
                                        Verify via OTP
                                    </Typography>
                                {/* </NavLink> */}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </>
    )
}

export default SecurityQuestionVerification;