import { Box, Button, Container, InputAdornment, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getActivityLogs, getAllCompaniesArray, getCSVLogs, getPdfLogs } from "../../resources/api-constants";
import { format } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import { LoadingButton } from "@mui/lab";


type Company = {
    id: string;
    fullCompanyName: string;
    companyName: string;
    company_number: string;
}[]


type FormData = {
    search: string;
};

const exportTypeList = [
    {
        name: 'exportCSVFile',
        value: 'CSV',
    },
    {
        name: 'exportPdfFile',
        value: 'PDF',
    }
]


const ActivityLog = () => {

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [companyNumber, setCompanyNumber] = useState<string>('');
    const [exportType, setExportType] = useState<string>('');
    const [companiesMenu, setCompaniesMenu] = useState<Company>([]);
    const [results, setResults] = useState([]);
    const [allActivityLogsData, setAllActivityLogsData] = useState<any>({});
    const [dateRange, setDateRange] = useState<DateRange>({ from: undefined, to: undefined });
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const datePickerRef = useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState(false);

    const { control, watch, reset } = useForm<FormData>({
        defaultValues: {
            search: '',
        },
    });

    const searchValue = watch('search');

    useEffect(() => {
        getAllCompaniesArray()
            .then(res => {
                const companyArray = res?.data?.map((item: any) => ({
                    id: item.id,
                    fullCompanyName: item.company_number + ' - ' + item.name, 
                    companyName: item.company_number + ' - ' + (item.name.length > 31 ? item.name.substring(0, 31) + '...' : item.name),
                    company_number: item.company_number,
                }))
                setCompaniesMenu(companyArray)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
                setShowDatePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datePickerRef]);

    const clearFilter = () => {
        setDateRange({ from: undefined, to: undefined });
        setCompanyNumber('');
        reset();
        setExportType('');
        setShowDatePicker(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0 + 1);
    };

    const handleDateChange = (range: DateRange) => {
        setDateRange({
            from: range?.from !== undefined ? range?.from : dateRange?.from,
            to: range?.to !== undefined ? range?.to : dateRange?.to,
        });
    };

    const handleDateClick = () => {
        setShowDatePicker(true);
    };

    const handleDatePickerOk = () => {
        setShowDatePicker(false);
    };

    const handleDatePickerClear = () => {
        setDateRange({ from: undefined, to: undefined });
        // setShowDatePicker(false);
    };

    const getAllActivityLogs = (pageNumber?: number | string, numberOfRows?: number | string, searchValue?: string, dateRange?: DateRange, companyNumber?: string) => {
        const payload = {
            page: pageNumber,
            page_size: numberOfRows,
            user_name: searchValue || '',
            company_number: companyNumber,
            start_date: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
            end_date: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
        };
        getActivityLogs(payload)
            .then(res => {
                console.log(res);
                setAllActivityLogsData(res?.data);
                setResults(res?.data?.results);
            })
            .catch(error => {
                console.log("🚀 ~ useEffect ~ error: pending api", error)
            })
    }

    const handleExportCSVFile = (searchValue?: string, dateRange?: DateRange, companyNumber?: string) => {
        setLoading(true);

        const payload = {
            user_name: searchValue || '',
            company_number: companyNumber,
            start_date: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
            end_date: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
        };

        getCSVLogs(payload)
            .then((res: any) => {
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', 'FileActivityLog.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => {
                console.log("csv file cannot download", error)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleExportPDFFile = (searchValue?: string, dateRange?: DateRange, companyNumber?: string) => {

        setLoading(true);

        const payload = {
            user_name: searchValue || '',
            company_number: companyNumber,
            start_date: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
            end_date: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
        };

        getPdfLogs(payload)
            .then(res => {
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', 'FileActivityLog.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => {
                console.log('Pdf file cannot download', error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleExportFile = () => {
        if (exportType === 'exportPdfFile') {
            handleExportPDFFile(searchValue, dateRange, companyNumber);
        } else {
            handleExportCSVFile(searchValue, dateRange, companyNumber);
        }
    }

    useEffect(() => {
        setPage(1);
        getAllActivityLogs(1, rowsPerPage, searchValue, dateRange, companyNumber);
    }, [searchValue, dateRange, companyNumber]);

    useEffect(() => {
        getAllActivityLogs(page, rowsPerPage, searchValue, dateRange, companyNumber);
    }, [page, rowsPerPage]);

    return (
        <>
            <Container component="main" sx={{ padding: '0px', marginLeft: '15px', marginTop: '15px' }} maxWidth='xl'>
                <Stack spacing={2}>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Typography variant='h4' sx={{ fontSize: '34px' }}>
                                Activity Log
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack spacing={2}>

                            <Stack direction='row' spacing={2}>

                                {/* Search By User Name */}
                                <Controller
                                    name="search"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Search User Name/Email"
                                            variant="outlined"
                                            sx={{ width: '26%' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                {/* Search By Company Name */}
                                <TextField
                                    id="companyNumber"
                                    select
                                    label="Company Number"
                                    value={companyNumber}
                                    onChange={(e) => { setCompanyNumber(e.target.value) }}
                                    sx={{ width: '23%', marginRight: '10px' }}
                                >
                                    {companiesMenu.map((option) => (
                                        <MenuItem title={option.fullCompanyName} key={option.id} value={option.company_number}>
                                            {option.companyName}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                {/* Filter By Date */}
                                <Box width={"30%"} sx={{ position: 'relative', display: 'inline-block' }}>
                                
                                    <TextField
                                        label="Start Date"
                                        value={dateRange.from ? format(dateRange.from, 'yyyy-MM-dd') : ''}
                                        onClick={handleDateClick}
                                        InputProps={{ readOnly: true }}
                                        sx={{ width: '48%', marginRight: '10px' }}
                                    />
                                    <TextField
                                        label="End Date"
                                        value={dateRange.to ? format(dateRange.to, 'yyyy-MM-dd') : ''}
                                        onClick={handleDateClick}
                                        InputProps={{ readOnly: true }}
                                        sx={{ width: '48%' }}
                                    />
                                    <Box ref={datePickerRef}>
                                        {showDatePicker && (
                                            <Box sx={{ position: 'absolute', zIndex: 1, bgcolor: 'background.paper', boxShadow: 3, }}>
                                                <DayPicker
                                                    mode="range"
                                                    numberOfMonths={1}
                                                    selected={dateRange}
                                                    toDate={new Date()}
                                                    defaultMonth={dateRange.from || new Date()}
                                                    // @ts-ignore
                                                    onSelect={handleDateChange}
                                                    styles={{
                                                        day: {
                                                            // @ts-ignore
                                                            selected: {
                                                                backgroundColor: '#0070B4',
                                                                color: '#fff',
                                                            },
                                                        },
                                                    }}
                                                />
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleDatePickerClear}
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: '#FDB913',
                                                                color: '#000000',
                                                            },
                                                            bgcolor: '#0073AE',
                                                            color: '#fff',
                                                            fontSize: '12px',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Clear
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleDatePickerOk}
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: '#FDB913',
                                                                color: '#000000',
                                                            },
                                                            bgcolor: '#0073AE',
                                                            color: '#fff',
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        OK
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                {/* Export Type */}
                                <TextField
                                    id="exportType"
                                    select
                                    label="Export Type"
                                    value={exportType}
                                    onChange={(e) => { setExportType(e.target.value) }}
                                    sx={{ width: '20%', marginRight: '10px' }}
                                >
                                    {exportTypeList.map((option) => (
                                        <MenuItem key={option.name} value={option.name}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Stack>

                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                

                                {
                                    exportType &&
                                    <LoadingButton
                                        loading={loading}
                                        variant="contained"
                                        type='button'
                                        onClick={handleExportFile}
                                        disableRipple
                                        sx={{
                                            '&:hover': {
                                                bgcolor: '#FDB913',
                                                color: '#000000',
                                            },
                                            bgcolor: '#0073AE',
                                            fontSize: '14px',
                                            width: '22%',
                                            marginY: '15px !important'
                                        }}
                                    >
                                        Download
                                    </LoadingButton>
                                }

                                <Button
                                    variant="contained"
                                    type='button'
                                    onClick={clearFilter}
                                    disableRipple
                                    sx={{
                                        '&:hover': {
                                            bgcolor: '#FDB913',
                                            color: '#000000',
                                        },
                                        bgcolor: '#0073AE',
                                        fontSize: '14px',
                                        width: '22%',
                                        marginY: '15px !important'
                                    }}
                                >
                                    Clear Filter
                                </Button>
                            </Stack>

                            <TableContainer component={Paper} >
                                <Table aria-label="simple table">
                                    <TableHead >
                                        <TableRow >
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>User Name</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Email Address</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px', width: '12vw' }}>Company Number</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Document Name</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Date</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Activity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {results?.length ? results?.map((file: any) => (
                                            <TableRow key={file?.id} >
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{file?.user_full_name}</Typography></TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{file?.user_email}</Typography></TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{file?.company_number ? file?.company_number : <span style={{ fontSize: '12px', paddingLeft: '3rem' }}>-</span>}</Typography></TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{file?.file_name}</Typography></TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{moment(file?.log_date, 'YYYY-MM-DD hh:mm:ss').format("MM/DD/YYYY")}</Typography></TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{file?.activity}</Typography></TableCell>
                                            </TableRow>

                                        )) :
                                            <TableRow>
                                                <TableCell>Data Not Found</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={8}
                                                count={allActivityLogsData?.count || 0}
                                                rowsPerPage={allActivityLogsData?.page_size || 5}
                                                page={allActivityLogsData?.current_page - 1 || 0}
                                                slotProps={{
                                                    select: {
                                                        inputProps: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                    },
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default ActivityLog;