export const LoginStyles = {
    loginContainer:{
        width: "520px",
        height: '409px'
  },

  boxContainer:{
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  cardContainer: { 
    width: '100%', 
    boxShadow: 15, 
},

formContainer: { mt: 1,
    maxWidth: '80%',
 },

 signInButton:{ 
    mt: 3, 
    mb: 2,
    bgcolor:'#0073AE',
    '&:hover': {
      bgcolor: '#FDB913',
      color:'#000000'
    },

   },

   forgotButton :{ mt: 1,
    color:'#0073AE',
    '&:hover': {
      color:'#FDB913'
    },
 },

 contactText: {mt: 2, textAlign: 'center' },

 cardHeading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

}