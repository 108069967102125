import React, {useState, useEffect} from 'react'
import { getUserProfile,deleteExternalUserFromCompany,deleteExternalUserFromAllCompany } from '../../resources/api-constants'
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,Tooltip,
    Typography,Container,
  } from '@mui/material';
import Swal from 'sweetalert2';
import InviteCompanyMember from '../ManageCompany/InviteCompanyMember';
import {ROUTES} from '../../resources/routes-constants'
import { useAuth } from '../../resources/AuthProvider'


const Profile = () => {

    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const extUserId = searchParams.get('extUserId');
    const userEmail = searchParams.get('userEmail');
    const userPhoneNo = searchParams.get('userPhoneNo');
    const navigate = useNavigate()

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [companyData, setCompanyData] = useState<any>()
    const [results, setResult] = useState<any>()
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0+1);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => {
      setModalOpen(true);
    };
    const handleClose = () => {
      setModalOpen(false);
    };

    const getExternalUserDetailsHandle = (pageNumber?:number,numberOfRows?:number,) => {
        const  payload ={
            id: userId,
            page:pageNumber ? pageNumber :page,
            page_size: numberOfRows ? numberOfRows :rowsPerPage,
            }
            getUserProfile(payload)
                .then(res => {
                    setCompanyData(res?.data?.companies)
                    setResult(res?.data?.user)
                })
                    
                .catch(error => {
                    console.log("🚀 ~ useEffect ~ error:", error)
                })
    }

    
    useEffect(() => {
      getExternalUserDetailsHandle()
    },[page,rowsPerPage])

  const {setToken} = useAuth()

  // const logout = () => {
  //     setToken('')
  //     navigate(ROUTES.LOGIN_ROUTE,{ replace: true })
  //  };
       

  return (
    <>
    <Container component="main" sx={{ padding:'0px', marginTop:'15px', marginLeft:'10px'}} maxWidth='xl'>
    <Stack spacing={2}>
       <Box>
       <Stack  direction='column'  width='70%'>
            <Box>
                <Typography variant='h4' sx={{fontSize:'34px'}}>
                   Profile
                </Typography>
            </Box>
            <Stack direction='column' mt={3} width='80%'>
               <Stack direction='column' justifyContent='space-between' width='84%'>
                    <Box mb={2}>
                        <Typography sx={{fontSize:'13px'}}>Name:&emsp;&emsp;&emsp;&emsp;&emsp;<Typography component='span' sx={{fontWeight:'bold'}}>{results?.full_name}</Typography></Typography>
                       
                    </Box>
                    <Box mb={2}>
                        <Typography sx={{fontSize:'13px',}}>Email:&emsp;&emsp;&emsp;&emsp;&emsp;<Typography component='span' sx={{fontWeight:'bold'}}>{results?.email}</Typography></Typography>
                        
                    </Box>
                    <Box mb={2}>
                        <Typography sx={{fontSize:'13px',}}>Phone No:&emsp;&emsp;&emsp;<Typography component='span' sx={{fontWeight:'bold'}}>{results?.phone_number}</Typography></Typography> 
                    </Box>
                    {
                      results?.role !== 'company_user' ?
                      <Box mb={2}>
                        <Typography sx={{fontSize:'13px',}}>Role:&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;<Typography component='span' sx={{fontWeight:'bold'}}>{results?.role}</Typography></Typography> 
                      </Box>
                    : null
                    }
                </Stack> 
            </Stack>
       </Stack>
       </Box>
       {
        results?.role === 'Company User' ?
        ( <Box>
          <Stack direction='row' justifyContent='space-between' alignItems='center' mt={5} mb={2}>
               <Typography variant='h6'>
                   Companies
               </Typography>
               
           </Stack>
          <TableContainer component={Paper} >
       <Table aria-label="simple table">
         <TableHead >
           <TableRow >
             
             <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Company Name</TableCell>
             <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Company Number </TableCell>
             <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Roles </TableCell>
           </TableRow>
         </TableHead>
         <TableBody>
        
           {companyData?.results.length ? companyData?.results?.map((company: any) => (
             <TableRow key={company?.id} >
               <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{company?.company?.name}</Typography></TableCell>
               <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{company?.company?.company_number}</Typography></TableCell>
               <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{company?.member_role.map((role: { name: string; }) => role.name).join(', ')}</Typography></TableCell>
             </TableRow>
            
           )) :
           <TableRow>
             <TableCell>Data Not Found</TableCell>
           </TableRow>
           }
         </TableBody>
         <TableFooter>
           <TableRow>
              <TablePagination
               rowsPerPageOptions={[5, 10, 25]}
               colSpan={8}
               count={companyData?.count || 0}
               rowsPerPage={companyData?.page_size || 5}
               page={companyData?.current_page-1 || 0}
               slotProps={{
                   select: {
                     inputProps: {
                       'aria-label': 'rows per page',
                     },
                   },
                 }}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
             /> 
           </TableRow>
         </TableFooter>
       </Table>
     </TableContainer>
          </Box>)
        :
        null
       }
      
       {/* <Button  variant='contained' onClick={logout} sx={{bgcolor: '#D32F2F', width:'15%', '&:hover': {
              bgcolor: '#FDB913',
              color:'#000000',
            }}}>Log out</Button> */}
    </Stack>
</Container>

</>
  )
}

export default Profile