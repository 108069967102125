import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    count:0,
}

const demoCountSlice = createSlice({
    name:'counter',
    initialState,
    reducers:  {
        increment: (state, action) =>{
            state.count =  state.count + action?.payload;
        },
        decrement: (state) =>{
            state.count--;
        }
    },
//    extraReducers: (builder) => {
//     builder.addCase(anotherAction,(state, action) => {
//         state.decrement: state.decrement - action.payload;
//     })
//    } 
})

export const {increment, decrement} = demoCountSlice.actions;
export default demoCountSlice.reducer;