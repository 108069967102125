import React,{ useEffect, useState, useRef } from 'react'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,Tooltip,TextField,MenuItem,
    Typography,
    Checkbox,
    Grid,
  } from '@mui/material';
import { getDocumentRecievedByAdmin,deleteAdminUser, getAllCompaniesTags, getExternalUserRoles } from '../../resources/api-constants';
import Swal from 'sweetalert2';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Dayjs } from 'dayjs';
import { getAllCompaniesArray } from '../../resources/api-constants';
import { DateRange, DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import '../../styles/DayPickerStyles.css'; // Import the custom CSS
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

type Company = {
  id:string;
  fullCompanyName: string;
  companyName:string;
  company_number:string;
}[]

const docType = [
  {
    name:'general',
    value:'General',
  },
  {
    name:'specific',
    value:'Specific',
  },
  {
    name:'general_by_tags',
    value:'General With Tags, Roles',
  }
]


const ReceivedDocument = () => {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [receivedDocumentsData, setReceivedDocumentsData] = useState<any>()
  const [results, setResult] = useState<any>()
  const [companyNumber, setCompanyNumber] = useState<string>('')
  const [documentType, setDocumentType] = useState<string>('general')
  const [dateRange, setDateRange] = useState<DateRange>({ from: undefined, to: undefined });
  const [tags, setTags] = useState<string>('')
  const [roles, setRoles] = useState<string>('')
  const [tagsArray, setTagsArray] = useState<any>([])
  const [roleArray, setRoleArray] = useState<any>([])
  const [companiesMenu, setCompaniesMenu] = useState<Company>([])
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [reuploadFileId, setReuploadFileId] = useState(searchParams.get('reuploadFileId') || '');
    

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectFile = (file: any) => {
    setSelectedFiles((prevSelectedFiles: any) => {
      const isSelected = prevSelectedFiles.some((selectedFile: any) => selectedFile.id === file.id);
      if (isSelected) {
        return prevSelectedFiles.filter((selectedFile: any) => selectedFile.id !== file.id); // Deselect the file
      } else {
        return [...prevSelectedFiles, file]; // Select the file
      }
    });
  };
  
  const handleDownloadSelectedFiles = () => {
    setIsLoading(true);
  
    selectedFiles.forEach((file: any) => {
        handleFileDownload(file?.reuploaded_file, file?.reuploaded_file_name);
    });
  
    setIsLoading(false);
  };

  const handleSelectAllFiles = (event:any) => {
    if (event.target.checked) {
      setSelectedFiles(results); // Select all files
    } else {
      setSelectedFiles([]); // Deselect all files
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0+1);
  };

  const getAllReceivedDocuments = (pageNumber?:number| string,numberOfRows?:number| string, reuploadFileId?: number | string, documentType?:string,dateRange?: DateRange, companyNumber?:string,  tags?:string, roles?:string) =>{
    const payload = {
      page: pageNumber,
      page_size: numberOfRows,
      reupload_file_id: reuploadFileId,
      doc_type: documentType,
      company_number: companyNumber,
      tags:tags,
      member_role:roles,
      start_date: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
      end_date: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
    };
    getDocumentRecievedByAdmin(payload)
    .then(res => {    
      console.log("🚀 ~ deleteAdminUserHandle ~ res:", res)    
      setReceivedDocumentsData(res?.data)
        setResult(res?.data?.results)       
    })
    .catch(error => {
        console.log("🚀 ~ useEffect ~ error: pending api", error)
    })
  }

  useEffect(()=>{
    getAllCompaniesArray()
    .then(res =>{
        const companyArray = res?.data?.map((item:any) =>( {
            id: item.id,
            fullCompanyName: item.company_number + ' - ' + item.name, 
            companyName: item.company_number + ' - ' + (item.name.length > 31 ? item.name.substring(0, 31) + '...' : item.name),
            company_number: item.company_number,
          }))
          setCompaniesMenu(companyArray)
    })
    .catch(error => {
        console.log(error)
    })
},[])

useEffect(()=>{
  getAllCompaniesTags()
  .then(res => {
       const appsArray = res?.data?.map((item:any) =>( {
            id: item?.id,
            name: item?.name,
          }))
          setTagsArray(appsArray)
    
  })
  .catch(error => {
      console.log(error)
  })
},[])

useEffect(()=>{
  getExternalUserRoles()
  .then(res => {
       const rollArray = res?.data?.map((item:any) =>( {
            id: item.id,
            name: item.name,
          }))
          setRoleArray(rollArray)
  })
  .catch(error => {
      console.log(error)
  })
},[])

useEffect(() => {
  setPage(1);
  if(reuploadFileId){
    getAllReceivedDocuments(1, rowsPerPage, reuploadFileId, '' , { from: undefined, to: undefined }, '', '','');
  }else{
    getAllReceivedDocuments(1, rowsPerPage, reuploadFileId, documentType, dateRange, companyNumber, tags, roles);
  }
  setSelectedFiles([]);
}, [documentType, dateRange, companyNumber, tags, roles]);

useEffect(() => {
  if(reuploadFileId){
    getAllReceivedDocuments(page, rowsPerPage, reuploadFileId, '' , { from: undefined, to: undefined }, '', '','');
  }else{
    getAllReceivedDocuments(page, rowsPerPage, reuploadFileId, documentType, dateRange, companyNumber, tags, roles);
  }
}, [page, rowsPerPage]);

  const clearAllSearchParams = () => {
    const url = new URL(window.location.href);
    url.search = ''; // Clear all search parameters
    window.history.replaceState(null, '', url);
    setSearchParams({});
  };

  const clearFilter = () => {

    if(reuploadFileId){
      clearAllSearchParams();
      setReuploadFileId('');
    }

    setDateRange({ from: undefined, to: undefined });
    setCompanyNumber('');
    setDocumentType('general');
    setPage(1);
    setTags('');  
    setRoles('');
    getAllReceivedDocuments(1, rowsPerPage, '', 'general', { from: undefined, to: undefined }, '', '','');
    setShowDatePicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  const handleDateChange = (range: DateRange) => {
    setDateRange({
      from: range?.from !== undefined ? range?.from : dateRange?.from,
      to: range?.to !== undefined ? range?.to : dateRange?.to,
    });
  };

  const handleDateClick = () => {
    setShowDatePicker(true);
  };

  const handleDatePickerOk = () => {
    setShowDatePicker(false);
  };

  const handleDatePickerClear = () => {
    setDateRange({ from: undefined, to: undefined });
    // setShowDatePicker(false);
  };

  const handleFileDownload = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <Box >
      <Stack spacing={2}>

        {
          !reuploadFileId && 
          <Stack direction='row' spacing={2}>
        <TextField
            id="documentType"
            select
            label="Document Type"
            value={documentType}
            defaultValue={documentType}
            onChange={(e) => { setDocumentType(e.target.value);setCompanyNumber('')}}
            sx={{width:'20%', marginRight:'10px'}}
          >
            {docType.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>

          {documentType === 'general_by_tags' && <TextField
            id="tags"
            select
            label="Tags"
            value={tags}
            defaultValue={tags}
            onChange={(e) => { setTags(e.target.value);}}
            sx={{width:'20%', marginRight:'10px'}}
          >
            {tagsArray?.map((option:any) => (
              <MenuItem key={option?.id} value={option?.id}>
                {option?.name}
              </MenuItem>
            ))}
          </TextField>}

        {documentType === 'specific' && <TextField
            id="companyNumber"
            select
            label="Company Number"
            onChange={(e) => { setCompanyNumber(e.target.value)}}
            sx={{width:'23%', marginRight:'10px'}}
          >
            {companiesMenu.map((option) => (
              <MenuItem title={option.fullCompanyName} key={option.id} value={option.company_number}>
                {option.companyName}
              </MenuItem>
            ))}
          </TextField>
          }

          {(documentType === 'specific' || documentType === 'general_by_tags') && <TextField
            id="roles"
            select
            label="Roles"
            onChange={(e) => { setRoles(e.target.value)}}
            sx={{width:'22%'}}
          >
            {roleArray.map((option:any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          }

          <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <TextField
                label="Start Date"
                value={dateRange.from ? format(dateRange.from, 'yyyy-MM-dd') : ''}
                onClick={handleDateClick}
                InputProps={{ readOnly: true }}
                sx={{ width: '40%', marginRight: '10px' }}
              />
              <TextField
                label="End Date"
                value={dateRange.to ? format(dateRange.to, 'yyyy-MM-dd') : ''}
                onClick={handleDateClick}
                InputProps={{ readOnly: true }}
                sx={{ width: '40%' }}
              />
              <Box ref={datePickerRef}>
              {showDatePicker && (
                <Box sx={{ position: 'absolute', zIndex: 1, bgcolor: 'background.paper', boxShadow: 3, }}>
                  <DayPicker
                    mode="range"
                    numberOfMonths={1}
                    selected={dateRange}
                    toDate={new Date()}
                    defaultMonth={dateRange.from || new Date()}
                    // @ts-ignore
                    onSelect={handleDateChange}
                    styles={{
                      day: { 
                        // @ts-ignore
                        selected: { 
                          backgroundColor: '#0070B4', 
                          color: '#fff',
                        },
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                  <Button
                      variant="contained"
                      onClick={handleDatePickerClear}
                      sx={{
                        '&:hover': {
                          bgcolor: '#FDB913',
                          color: '#000000',
                        },
                        bgcolor: '#0073AE',
                        color: '#fff',
                        fontSize: '12px',
                        marginRight:'10px',
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleDatePickerOk}
                      sx={{
                        '&:hover': {
                          bgcolor: '#FDB913',
                          color: '#000000',
                        },
                        bgcolor: '#0073AE',
                        color: '#fff',
                        fontSize: '12px',
                      }}
                    >
                      OK
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            </Box>
        
        </Stack>
        }

        {
          reuploadFileId && 
          <Stack direction='row' spacing={2}>
            <TextField
                id="_documentName"
                value={results?.[0]?.reuploaded_file_name || ''}
                label="Document Name"
                sx={{ width: '42%' }}
                InputProps={{
                    readOnly: true,
                }}
            >
            </TextField>
          </Stack>
        }
      
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={0}>
        <Stack direction="row" spacing={2} width="50%">
          <Button
            variant="contained"
            type="button"
            onClick={clearFilter}
            disableRipple
            sx={{
              '&:hover': {
                bgcolor: '#FDB913',
                color: '#000000',
              },
              bgcolor: '#0073AE',
              fontSize: '12px',
              width: '40%'
            }}
          >
            Clear Filter
          </Button>

          {reuploadFileId && (
            <Button
              variant="contained"
              type="button"
              onClick={() => { navigate(-1) }}
              disableRipple
              sx={{
                '&:hover': {
                  bgcolor: '#FDB913',
                  color: '#000000',
                },
                bgcolor: '#0073AE',
                fontSize: '12px',
                width: '40%',
                marginLeft: 2
              }}
            >
              Back to Notifications
            </Button>
          )}
        </Stack>

        {!!selectedFiles.length && (
          <LoadingButton
            variant="contained"
            type="button"
            loading={isLoading}
            onClick={handleDownloadSelectedFiles}
            startIcon={<CloudDownloadOutlinedIcon />}
            disableRipple
            sx={{
              '&:hover': {
                bgcolor: '#FDB913',
                color: '#000000',
              },
              bgcolor: '#0073AE',
              fontSize: '12px',
              width: '20%'
            }}
          >
            Download Files
          </LoadingButton>
        )}
      </Box>

      <TableContainer component={Paper} >
        <Table aria-label="simple table">
          <TableHead >
            <TableRow >
              <TableCell sx={{fontWeight:'bold', fontSize:'12px', }}>Document Name</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px', }}>Document Type</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px', }}>User Name</TableCell>
              {documentType === 'general_by_tags' && <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Tags</TableCell>}
              {documentType === 'specific' && <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Company Number</TableCell>}
              {(documentType === 'specific' || documentType === 'general_by_tags' ) && <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Roles</TableCell>} 
               <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Reupload Date</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Email Address</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Action</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>
                <Checkbox
                  color="primary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={rowCount > 0 && numSelected === rowCount}
                  checked={selectedFiles.length >= results?.length}
                  onChange={handleSelectAllFiles}
                  inputProps={{
                    'aria-label': 'select all file',
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
         
            {results?.length ? results?.map((files: any) => (
              <TableRow key={files?.id} >
                <TableCell ><Typography sx={{ fontSize:'12px', }}>{files?.reuploaded_file_name}</Typography></TableCell>
                <TableCell ><Typography sx={{ fontSize:'12px', }}>{files?.original_file_type}</Typography></TableCell>
                <TableCell ><Typography sx={{ fontSize:'12px', width:'10vw'}}>{files?.user ? files?.user?.full_name : files?.user_full_name}</Typography></TableCell>
                {documentType === 'general_by_tags' &&  <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{files?.original_file_tags?.name}</Typography></TableCell>}
                {documentType === 'specific' && <TableCell ><Typography sx={{ fontSize:'12px', width:'9vw'}}>{files?.original_file_company_number}</Typography></TableCell>}
                {(documentType === 'specific' || documentType === 'general_by_tags') && <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{files?.original_file_roles?.name ? files?.original_file_roles?.name : <span style={{fontSize: '12px', paddingLeft: '1rem'}}>-</span>}</Typography></TableCell>}
                <TableCell ><Typography sx={{ fontSize:'12px', width:'7vw'}}>{moment(files?.reupload_date, 'YYYY-MM-DD hh:mm:ss').format("MM/DD/YYYY")}</Typography></TableCell>
                <TableCell  sx={{ fontSize:'12px'}}><Tooltip title={files?.user?.email} arrow><Typography sx={{ fontSize:'12px', }}>{files?.user ? files?.user?.email : files?.user_email}</Typography></Tooltip></TableCell>
                <TableCell sx={{ fontSize:'12px'}}>
                    <Stack direction='row'>
                            <Button
                                 variant="text"
                                 type='button'
                                 size='small'
                                 component="a"
                                // href={files?.reuploaded_file}
                                 onClick={() => handleFileDownload(files?.reuploaded_file, files?.reuploaded_file_name)}
                                  sx={{color:'#000000' ,justifyContent:'left', marginRight:'10px',fontSize:'11px','&:hover': {
                                    color: '#FDB913',
                                    background:'#fff'
                                  },}}
                                  disableRipple
                             >
                                 <CloudDownloadOutlinedIcon />
                             </Button>           
                    </Stack>
                </TableCell>
                <TableCell>
                  <Checkbox
                    color="primary"
                    checked={selectedFiles.some((selectedFile : any) => selectedFile?.id === files?.id)}
                    onChange={() => handleSelectFile(files)}
                    inputProps={{
                      'aria-label': 'select all file',
                    }}
                  />
                </TableCell>
              </TableRow>
             
            )) :
            <TableRow>
              <TableCell>Data Not Found</TableCell>
            </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
               <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={8}
                count={receivedDocumentsData?.count || 0}
                rowsPerPage={receivedDocumentsData?.page_size || 5}
                page={receivedDocumentsData?.current_page-1 || 0}
                slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                    },
                  }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> 
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      </Stack>
    </Box>
  )
}

export default ReceivedDocument