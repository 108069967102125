import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const ContactUs = () => {
    return (
        <>
            <Container component="main" sx={{ padding: '0px', marginTop: '15px', marginLeft: '15px' }} maxWidth='xl'>
                <Stack spacing={2}>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Typography variant='h4' sx={{fontSize:'34px'}}>
                                Contact Us
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Box mt={3}>
                            <Typography fontWeight={"bold"}>
                            NTCA's Benefits Resource Specialists are available to answer questions or assist with your account. 
                            </Typography>
                            {/* <Typography fontWeight={"bold"}>
                                You can reach us via phone or email using the following contact details:
                            </Typography> */}

                            <Grid container spacing={2} mt={3}>
                                <Grid item xs={2}>
                                    Phone:
                                </Grid>
                                <Grid item xs={10}>
                                    (828) 281-9000
                                </Grid>
                                <Grid item xs={2}>
                                    Email:
                                </Grid>
                                <Grid item xs={10}>
                                    <a href="mailto:benefitsresource@ntca.org">
                                    benefitsresource@ntca.org 
                                    </a>
                                </Grid>
                                <Grid item xs={2}>
                                    Address:
                                </Grid>
                                <Grid item xs={10}>
                                    1234 Fictional Ave <br/>
                                    Imaginary City, <br/>
                                    CA 90210 <br/>
                                    USA
                                </Grid>
                                <Grid item xs={2}>
                                    Business Hours:
                                </Grid>
                                <Grid item xs={10}>
                                    9 a.m. to 7 p.m. ET
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default ContactUs;