import React,{ useEffect, useState } from 'react'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,Tooltip,
    Typography,
  } from '@mui/material';
import { getAdminUsers,deleteAdminUser } from '../../resources/api-constants';
import Swal from 'sweetalert2';

const AdminUsers = () => {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [adminUserData, setAdminUserData] = useState<any>()
  const [results, setResult] = useState<any>()

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0+1);
  };

  const getAllAdminUsers = (pageNumber?:number,numberOfRows?:number) =>{
    const payload = {
        page:pageNumber ? pageNumber :page,
        page_size: numberOfRows ? numberOfRows :rowsPerPage,
    }
    getAdminUsers(payload)
    .then(res => {        
      setAdminUserData(res?.data)
        setResult(res?.data?.results)       
    })
    .catch(error => {
        console.log("🚀 ~ useEffect ~ error: pending api", error)
    })
  }

  useEffect(() => {
    getAllAdminUsers();
  },[page,rowsPerPage])

  const deleteMasterUserConfirm = (id:number) => {
    Swal.fire({
        text:'Are you sure you want to delete the User?',
        icon:'question',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
    }).then(result =>{
        if(result.isConfirmed){
          deleteAdminUserHandle(id)
        }
    })
  }

  const deleteAdminUserHandle = (id:number) => { 
     const payload= {
        id,
     }
     deleteAdminUser(payload)
     .then(res => {
       Swal.fire({
        text: "User has been successfully deleted.",
        icon: "success",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
       getAllAdminUsers(1,5)
     })
     .catch(error => {
       Swal.fire({
        text: "Error in deleting the User.",
        icon: "error",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
     })
  }

const data = results && results.map((item :any) => {
    return ( item.id)
})

  return (
    <Box >
      <TableContainer component={Paper} >
        <Table aria-label="simple table">
          <TableHead >
            <TableRow >
              <TableCell sx={{fontWeight:'bold', fontSize:'12px', }}>Name</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Email</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
         
            {results?.length ? results?.map((masterUser: any) => (
              <TableRow key={masterUser?.id} >
                <TableCell ><Typography sx={{ fontSize:'12px', width:'14vw'}}>{masterUser?.full_name}</Typography></TableCell>
                <TableCell  sx={{ fontSize:'12px'}}><Tooltip title={masterUser?.email} arrow><Typography sx={{ fontSize:'12px', }}>{masterUser?.email}</Typography></Tooltip></TableCell>
                <TableCell sx={{ fontSize:'12px'}} colSpan={9}>
                    <Stack direction='row'>
                            <Button
                                 variant="text"
                                 type='button'
                                 size='small'
                                  sx={{color:'#cb2c30', marginRight:'10px',fontSize:'11px','&:hover': {
                                    //color: '#FDB913',
                                   textDecoration: 'underline'
                                  },}}
                                 onClick={() => deleteMasterUserConfirm(masterUser?.id)}
                                  disableRipple
                             >
                                 Delete User
                             </Button>                       
                    </Stack>
                </TableCell>
              </TableRow>
             
            )) :
            <TableRow>
              <TableCell>Data Not Found</TableCell>
            </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
               <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={8}
                count={adminUserData?.count || 0}
                rowsPerPage={adminUserData?.page_size || 5}
                page={adminUserData?.current_page-1 || 0}
                slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                    },
                  }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> 
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default AdminUsers