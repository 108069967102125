import React, {useState, useEffect} from 'react'
import InviteCompanyMember from './AddNewCompany';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useForm, Controller } from 'react-hook-form';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,Tooltip,
    Typography,Container,MenuItem, Select, SelectProps ,
  } from '@mui/material';
import Swal from 'sweetalert2';
import { getAllCompanies, deleteCompany } from '../../resources/api-constants';
import { Link, NavLink } from 'react-router-dom';
import Routes from '../../routes/routes';
import { ROUTES } from '../../resources/routes-constants';
import { styled } from '@mui/system';
import { useAppSelector } from '../../store/store';
import { useAppDispatch } from '../../store/store';
import { CompanyDataSuccess } from '../../store/slice/getAllCompaniesSlice';
import moment from 'moment';

const CustomTablePagination = styled(TablePagination)(({ theme }) => ({

}));


type FormData = {
    search: string;
  };

const AcceptedInvitation = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [companyData, setCompanyData] = useState<any>()
  // const [results, setResult] = useState<any>()

  const {allCompaniesData, results} = useAppSelector<any>(state => state.getAllCompaniesData)
  const dispatch = useAppDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0+1);
  };

    const [value, setValue] = useState('1');   
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => {
      setModalOpen(true);
    };
    const handleClose = () => {
      setModalOpen(false);
    };

const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  setValue(newValue);
};

const { control, watch, reset } = useForm<FormData>({
    defaultValues: {
      search: '',
    },
  });

  const searchValue = watch('search');

  useEffect(() => {
    if (searchValue || searchValue == '') {
          getAllCompaniesHandel(1,5,searchValue)
        }
   
  }, [searchValue]);

 
  const getAllCompaniesHandel = (pageNumber?:number | string,numberOfRows?:number | string, searchTerm?:string) =>{
    const payload = {
        page:pageNumber ? pageNumber :page,
        page_size: numberOfRows ? numberOfRows :rowsPerPage,
        search:searchTerm ? searchTerm : '',
        //status:'accepted',
    }
    getAllCompanies(payload)
    .then(res => {        
      // setCompanyData(res?.data)
      // setResult(res?.data?.results)
      //@ts-ignore
      dispatch(CompanyDataSuccess(res))
    })
    
    .catch(error => {
        console.log("🚀 ~ useEffect ~ error: pending api", error)
    })
  }

  useEffect(() => {
    getAllCompaniesHandel('','',searchValue);
  },[page,rowsPerPage])

  const deleteCompanyConfirm = (id:number) => {
    Swal.fire({
        text:'Are you sure you want to delete the Company?',
        icon:'question',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
    }).then(result =>{
        if(result.isConfirmed){
          deleteCompanyHandle(id)
        }
    })
  }

  const deleteCompanyHandle = (id:number) => { 
     const payload= {
        id,
     }
     deleteCompany(payload)
     .then(res => {
       Swal.fire({
        text: "Company has been successfully deleted.",
        icon: "success",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
       setPage(1)
       getAllCompaniesHandel(1,5)
     })
     .catch(error => {
       Swal.fire({
        text: "Error in deleting the User.",
        icon: "error",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
     })
  }

  const clearSearch = () => {
    reset()
    getAllCompaniesHandel()
  } 


  return (
    <>
    
           <Box width='100%' sx={{overflow:'hidden'}}>
            <Stack mt={2} mb={2} direction='row' justifyContent='space-between' alignItems='center' width='50%'>
            <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Search Company Name"
                    variant="outlined"
                    sx={{width:'65%'}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
            />
            <Button
                variant="contained"
                type='button'
                onClick={clearSearch}
                 disableRipple
                 sx={{'&:hover': {
                  bgcolor: '#FDB913',
                  color:'#000000'
                },
                bgcolor: '#0073AE'
              }}
            >
                Clear Search
            </Button>     
            </Stack>
           </Box>
      <Box>
        <TableContainer component={Paper} sx={{width:'100%'}} >
        <Table aria-label="simple table" width='100%'>
          <TableHead >
            <TableRow >
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Company Number</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Company Name</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Tags</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px',}}>Date Created</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px',}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
         
            {results?.length ? results?.map((company: any) => (
              <TableRow key={company?.id} >
                <TableCell >
                    <NavLink className="custom-navlink" to={{
                            pathname: ROUTES.COMPANY_DETAILS,
                            search: `?companyNumber=${encodeURIComponent(company?.company_number)}&companyName=${encodeURIComponent(company?.name)}
                                    &adminName=${encodeURIComponent(company?.admin?.full_name)}&companyId=${encodeURIComponent(company?.id)}&adminEmail=${encodeURIComponent(company?.admin?.email)}`
                            }}
                    > 
                        <Typography sx={{ fontSize:'12px',}}>{company?.company_number}</Typography>
                    </NavLink>
                </TableCell>
                <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{company?.name}</Typography></TableCell>
                <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px'}}>{company?.tags?.map((tag: { name: any; }) => tag.name).join(', ')}<br /></Typography></TableCell>
                <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{moment(company?.created_date).format("MM/DD/YYYY")}</Typography></TableCell>
                <TableCell sx={{ fontSize:'12px',width:'12vw'}} >
                    
                            <Button
                                 variant="text"
                                 type='button'
                                 size='small'
                                  sx={{color:'#cb2c30', marginRight:'10px',fontSize:'10px','&:hover': {
                                    // color: '#FDB913',
                                   textDecoration: 'underline'
                                  }}}
                                 onClick={() => deleteCompanyConfirm(company?.id)}
                                  disableRipple
                             >
                                 Remove Company
                             </Button>                       
                    
                </TableCell>
              </TableRow>
             
            )) :
            <TableRow>
              <TableCell>Data Not Found</TableCell>
            </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
               <CustomTablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={8}
                count={allCompaniesData?.count || 0}
                rowsPerPage={allCompaniesData?.page_size || 5}
                page={allCompaniesData?.current_page-1 || 0}
                slotProps={{
                 select:{ inputProps: {
                    'aria-label': 'rows per page',
                  },
                  renderValue: (value) => `${value}`,
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  },
                }
                }}
                 
                onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
              /> 
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
           </Box>
           
    </>
  )
}

export default AcceptedInvitation