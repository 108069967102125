import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import forgotUserPassword from '../../store/actions/forgotPasswordAction';
import { useNavigate, useLocation, } from 'react-router-dom';
import { useAuth } from '../../resources/AuthProvider';
import { ROUTES } from '../../resources/routes-constants';
import { useForm, SubmitHandler } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { reqForgotPassLink } from '../../resources/api-constants';
import {Dialog,DialogContent,DialogTitle} from '@mui/material'
import { EmailSentScreen } from '../../components/EmailSentScreen';
import { ForgotPass } from './ForgotPasswordStyles';
import Swal from 'sweetalert2';
const theme = createTheme({
    
});
interface IFormInput {
  email: string;

}

const RequestForgotPassword = () => {
 
  const [message, setMessage] = useState({
    heading:'',
    content:'',
  });

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    message.heading == 'Success' && navigate(-1)
  };

  const [isEmailSent, setIsEmailSent] = useState(false)
    const {loading, loginData, error} = useAppSelector(state => state.login)
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const { setToken} = useAuth();
    const from = location.state?.from?.pathname || ROUTES.ABOUTPAGE_ROUTE;

    const { register, handleSubmit, formState: { errors }, getValues } = useForm<IFormInput>({
      defaultValues:{
          email:'',
      }
  });

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        
        const payload = {
            email: data.email.toLowerCase(),
        }

        reqForgotPassLink(payload)
          .then((response) => {
            setIsEmailSent(true)
          } )
          .catch(error => {
            Swal.fire({
              text: `${error.response.data.error}`,
              icon: "error",
              customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer : 'custom-html-container',
               }
            })
            // setMessage({heading: 'Error', content:error.response.data.error});
            // setModalOpen(true)
            
          })
      };


  return (
  <>
    <ThemeProvider theme={theme}>
    { !isEmailSent ?  
    
    <Container component="main" sx={ForgotPass.mainContainer} 
    >
      <Box
        sx={ForgotPass.boxContainer}
      >
          <Box
              component="img"
              alt="NTCA Logo"
              src={NtcaLogo}
              mb={5}
          />
      <Card sx={ForgotPass.cardContainer}>
          <CardContent>
            <Box
              sx={ForgotPass.cardBox}
            >    

        <Typography component="h1" variant="h5" gutterBottom sx={{
          color:'#475260'
        }}>
          Forgot Password
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={ForgotPass.heading}>
        Enter the email associated with your account to receive a password reset link.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={ForgotPass.formContainer}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Registered Email Address"
            autoComplete="email"
            autoFocus
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Enter a valid Email address',
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={ForgotPass.submitButton}
          >
            Send Password Reset Link
          </Button>
          
          <Button
              onClick={() => navigate(-1)}
              variant="text"
              fullWidth
              sx={ForgotPass.cancelButton}
               disableRipple
          >
                  Cancel
          </Button>
          
        </Box>
        
      </Box>
      </CardContent>
        </Card>

      </Box>
    </Container>
    :
    <EmailSentScreen email={getValues('email')} />
    }
  </ThemeProvider>

  {/* <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <Typography variant='h4' sx={ForgotPass.dialogHeading}>
              {message.heading}
          </Typography>
          
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1' sx={{
            fontSize: 18
          }}>
          {message.content}
          </Typography>
        </DialogContent>
        
        <Box sx={{
          margin:'auto',
          
        }}
        >
        <Button
            onClick={handleClose}
            type="submit"
            variant="contained"
            color="primary"
            sx={ForgotPass.okButton}
          >
            OK
          </Button>
          </Box>
       
      </Dialog> */}

  </>
  )
}

export default RequestForgotPassword

