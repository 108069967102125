import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    allNotificationData:{},
    results:[],
    page: 1,
    rowsPerPage: 5
}

const allNotificationSlice = createSlice({
    name:'notificationData',
    initialState,
    reducers:  {
        NotificationSuccess: (state:any, action:any) => {
            state.results = action.payload.data.results
            state.allNotificationData = action.payload.data
        },
        NotificationFailed: (state) =>{
            state = state;
        },
        updatePage: (state, action) => {
            state.page = action.payload;
        },
        updateRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        }
    },
})

export const {NotificationSuccess, NotificationFailed, updatePage, updateRowsPerPage} = allNotificationSlice.actions;
export default allNotificationSlice.reducer;