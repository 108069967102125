import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    allFAQData:{},
    results:[],
}

const allFAQSlice = createSlice({
    name:'faqData',
    initialState,
    reducers:  {
        FAQSuccess: (state:any, action:any) => {
            state.results = action.payload.results
            state.allFAQData = action.payload
        },
        FAQFailed: (state) =>{
            state = state;
        }
    },
})

export const {FAQSuccess, FAQFailed} = allFAQSlice.actions;
export default allFAQSlice.reducer;