import { createSlice } from "@reduxjs/toolkit";
import forgotUserPassword from "../actions/forgotPasswordAction";
const initialState = {
    loading:false,
    newPasswordData:[],
    error:''
}

const forgotPasswordlice = createSlice({
    name: 'forgotPassword',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(forgotUserPassword.pending, (state) => {
            state.loading = true
        })
        builder.addCase(forgotUserPassword.fulfilled, (state, action) => {
            state.loading = false
            state.newPasswordData = action.payload
            state.error = ''
        })
        builder.addCase(forgotUserPassword.rejected, (state,action) => {
            state.loading = false
            state.newPasswordData = []
            state.error = action.error.message || ''
        })
    },
})

export default forgotPasswordlice.reducer;