import React, { useState,useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
  Checkbox,
  Backdrop,
  Tooltip,
} from '@mui/material';
import { useForm, Controller, useWatch, SubmitHandler } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Dayjs } from 'dayjs';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { getDocumentsSendByAdmin } from '../../resources/api-constants';
import { SentFileByAdminSuccess } from '../../store/slice/getAllFilesSentByAdminSlice';
import { useAppDispatch } from '../../store/store';
import InfoIcon from '@mui/icons-material/Info';
import InfoDialog from './InfoDialog';
interface IFormInput {
  files: { file: File, name: string }[];
  isReminder?: boolean;
  isReUploadRequired?: boolean;
  reminderTime?: Dayjs | null;
}

interface UploadStatus {
  status: 'idle' | 'uploading' | 'success' | 'error';
  errorMessage?: string;
}

// Custom Backdrop component
const CustomBackdrop = (props: any) => {
  return <Backdrop {...props} onClick={(e) => e.stopPropagation()} />;
};

const UploadNewDocument: React.FC<{ onOpen: any; onClose: any;}> = (props) => {
  const { onOpen, onClose } = props;
  const { control, handleSubmit, setValue, watch, formState: { errors }, reset,register } = useForm<IFormInput>({
    defaultValues: {
      files: [],
      isReminder: false,
      isReUploadRequired: false,
      reminderTime: null,
    },
  });

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const [uploadStart, setUploadStart] = useState(false)
  const [editingFileName, setEditingFileName] = useState<string | null>(null);
  const [disableUploadButton, setDisableUploadButton] = useState(false)
  const [uploadStatus, setUploadStatus] = useState<{ [key: string]: UploadStatus }>({});
  const isReminderSelected = useWatch({ control, name: "isReminder" });
  const [hideUploadButton, setHideUploadButton] = useState(false)
  const selectedFiles = watch('files');
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);
  const dispatch = useAppDispatch();
  
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadStatus({})
    const files = e.target.files;
    if (files) {
      const fileArray = Array.from(files).map(file => ({ file, name: file.name }));
      setValue('files', fileArray, { shouldValidate: true });
    }
  };

  const checkAllUploadsSuccessful = () => {
    return selectedFiles?.every(fileObj => uploadStatus[fileObj.file.name]?.status === 'success');
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setUploadStart(true)
    setDisableUploadButton(true)
    const token = localStorage.getItem("token");
    const reminderDateTime = data?.reminderTime?.format('YYYY-MM-DD HH:mm:ss');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    setCancelTokenSource(source);
      
    for (const { file, name } of data.files) {
      if (source.token) {
      const formData = new FormData();
      formData.append('file', file, name);
      formData.append('reminder_time', reminderDateTime?.toString() || '');
      formData.append('is_reminder', String(data.isReminder));
      formData.append('is_reupload_required', String(data.isReUploadRequired));

      setUploadStatus((prev) => ({ ...prev, [file.name]: { status: 'uploading' } }));
      
      await axios.post('https://solitary-recipe-42467.botics.co/document/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: token ? "Token " + token : null
      },
      cancelToken: source.token,
    })
    .then(res => {
      setUploadStatus((prev) => ({ ...prev, [file.name]: { status: 'success' } }));
      console.log("🚀 ~ constonSubmit:SubmitHandler<IFormInput>= ~ res:", res)
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        setUploadStatus((prev) => ({ ...prev, [file.name]: { status: 'idle' } }));
        return;
      }
      setUploadStatus((prev) => ({
        ...prev,
        [file.name]: { status: 'error', errorMessage: error.response?.data?.error || 'Upload failed' },
      }));
    })
  }
    }
    setUploadStart(false)
    setHideUploadButton(true)
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('File upload cancelled by user');
      }
    };
  }, [cancelTokenSource]);

  useEffect(() => {
    if (!uploadStart && selectedFiles?.length > 0 && checkAllUploadsSuccessful()) {
      closeDialog();
      Swal.fire({
        text: "Documents Successfully Uploaded.",
        icon: "success",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
    }).then((result) => {
        if (result.isConfirmed) {
           const payload = {
            page:1,
            page_size: 5,
            doc_type:'general',
            company_number:'',
            start_date:'',
            end_date:'',
           }
           getDocumentsSendByAdmin(payload)
           .then((res:any) => {
               dispatch(SentFileByAdminSuccess(res.data))
            
           })
           .catch((error:any) => {
               console.log("🚀 ~ useEffect ~ error: pending api", error)
           })
          }
        

      })
        .catch(error => {
          closeDialog();
        })
    }
  }, [uploadStatus,uploadStart]);

  const removeFile = (index: number) => {
    const updatedFiles = selectedFiles ? [...selectedFiles] : [];
    updatedFiles.splice(index, 1);
    setValue('files', updatedFiles, { shouldValidate: true });
  };

  const handleFileNameChange = (index: number, newName: string) => {
    const updatedFiles = [...(selectedFiles || [])];
    updatedFiles[index].name = newName;
    setValue('files', updatedFiles);
  };

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('File upload cancelled by user');
      setCancelTokenSource(null); 
      setUploadStart(false); 
    }
  };

  const closeDialog = () => {
    const payload = {
      page:1,
      page_size: 5,
      doc_type:'general',
      tags: '',
      member_role: '',
      company_number:'',
      start_date:'',
      end_date:'',
     }
     getDocumentsSendByAdmin(payload)
     .then((res:any) => {
         dispatch(SentFileByAdminSuccess(res.data))
      
     })
     .catch((error:any) => {
         console.log("🚀 ~ useEffect ~ error: pending api", error)
     })
    reset();
    onClose();
  };

  const successfulUploads = Object.values(uploadStatus).filter(status => status.status === 'success').length;
  return (
    <>
    <Dialog
      open={onOpen}
      onClose={onClose}
      fullWidth={true}
      slots={{ backdrop: CustomBackdrop }}
      disableEscapeKeyDown
      maxWidth='sm'
      scroll={'body'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
        error: errors,
      }}
    >
      <Stack direction='row' sx={{justifyContent:'space-between'}}>
      <DialogTitle sx={{ fontWeight: 'bold' }}>
        Upload Documents
      </DialogTitle>
      <Tooltip title="How to Upload" sx={{marginTop:'20px', marginRight:'30px',cursor:'pointer'}}>
        <InfoIcon onClick={handleOpen}/>
      </Tooltip>
      </Stack>
      
      <DialogContent sx={{ height: '500px' }}>
        <Stack direction='column' width='100%'>
          <Controller
            name="isReUploadRequired"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="Recipients will need to return the document(s)"
                sx={{ marginBottom: '10px' }}
              />
            )}
          />
          {errors.isReUploadRequired && (
            <Typography color="error" variant="body2">
              {errors.isReUploadRequired.message}
            </Typography>
          )}

          <Controller
            name="isReminder"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="Set a reminder to return the document(s)"
              />
            )}
          />
          {errors.isReminder && (
            <Typography color="error" variant="body2">
              {errors.isReminder.message}
            </Typography>
          )}

          {isReminderSelected && (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="reminderTime"
                  control={control}
                  rules={{ required: isReminderSelected ? 'Date and time are required' : false }}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      //@ts-expect-error
                      renderInput={(params:any) => <TextField {...params} size='small' />}
                      label="Select Date and Time"
                      sx={{ width: '65%', marginLeft: '25px', marginTop: '10px' }}
                    />
                  )}
                />
                {errors.reminderTime && (
                  <Typography color="error" variant="body2" sx={{ marginLeft: '25px' }}>
                    {errors.reminderTime.message}
                  </Typography>
                )}
              </LocalizationProvider>
            </>
          )}
        </Stack>
        <Stack
          mt={2}
          justifyContent='center'
          alignItems='center'
          sx={{
            border: '1px dashed #e0e0e0',
            width: '100%',
            padding: '25px',
            borderRadius: '5px'
          }}
        >
          <input
            type="file"
            id="file-upload"
            {...register('files', {
              validate: (files: string | any[]) => files.length > 0 || 'You must select at least one file'
            })}
            disabled={disableUploadButton}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            multiple
            accept='.doc, .docm, .docx, .dot, .dotm, .dotx, .htm, .html, .msg, .pdf, .rtf, .txt, .wpd, .xps, .jpg, .csv, .xls, .xlsm, .xlsx'
          />
          <label htmlFor="file-upload">
            <Typography><UploadFileIcon sx={{ color: '#0070B4', marginLeft: '60px' }} /></Typography>
            <Button variant="text" component="span" sx={{ '&:hover': { textDecoration: 'underline' } }} disabled={disableUploadButton}>
              Click to upload
            </Button>
          </label>
          <Typography sx={{ fontSize: '14px', color: '#666666',textAlign:'center'}}>
            .doc, .docm, .docx, .dot, .dotm, .dotx, .htm, .html, .msg, .pdf, .rtf, .txt, .wpd, .xps jpg Spreadsheet-.csv, .xls, .xlsm, .xlsx
          </Typography>
          {errors.files && (
            <Typography color="error" variant="body2">
              {errors.files.message}
            </Typography>
          )}
        </Stack>
        {selectedFiles.length > 0 && (
              <Typography variant="body2" sx={{ mt: 2, color:'green' }}>
                {successfulUploads} of {selectedFiles.length} files successfully uploaded
              </Typography>
            )}
        {selectedFiles && selectedFiles.length > 0 && (
          <List sx={{ marginTop: '16px', width: '100%', maxWidth: '100%', border: '1.7px dashed rgba(123, 97, 255, .5)', borderRadius: '5px' }}>
            {selectedFiles.map((fileObj, index) => (
              <ListItem key={index}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    {
                      uploadStatus[fileObj.file.name]?.status === 'uploading' ? <CircularProgress size={24} /> :
                        uploadStatus[fileObj.file.name]?.status === 'success' ? <CheckCircleIcon color="success" /> :
                          uploadStatus[fileObj.file.name]?.status === 'error' ? <ErrorIcon color="error" /> :
                            <DeleteIcon onClick={() => removeFile(index)} />
                    }
                  </IconButton>
                }
              >
                {
                (editingFileName === fileObj.file.name &&
                 uploadStatus[fileObj.file.name]?.status !== 'uploading' &&
                 uploadStatus[fileObj.file.name]?.status !== 'success' &&
                 uploadStatus[fileObj.file.name]?.status !== 'error'
                )
                ? (
                  <TextField
                    value={fileObj.name}
                    onChange={(e) => handleFileNameChange(index, e.target.value)}
                    onBlur={() => setEditingFileName(null)}
                    autoFocus
                  />
                ) : (
                <>
                <UploadFileIcon sx={{ color: uploadStatus[fileObj.file.name]?.status === 'error' ? 'red' : '#0070B4', marginRight: '15px' }} />
                <ListItemText sx={{cursor: 'pointer',
                 color:uploadStatus[fileObj.file.name]?.status === 'error' ? 'red' : ''}}
                 primary={fileObj?.name} 
                 secondary={`${Number(fileObj.file?.size / 1024).toFixed(1)}kb`} 
                 primaryTypographyProps={{ fontSize: '12px', onClick: () => setEditingFileName(fileObj.file.name) }}
                 secondaryTypographyProps={{fontSize:'11px'}}
                 />
                {uploadStatus[fileObj.file.name]?.status === 'error' && (
                  <Typography color="error" variant="body2" component='div'>
                    {uploadStatus[fileObj.file.name].errorMessage}
                  </Typography>
                )}
                </>
              )}
              
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
      {uploadStart ?
        <Button onClick={cancelUpload} sx={{color:'#0073AE', '&:hover': { color: '#FDB913' } }}>Cancel Upload</Button>
        :
        <Button onClick={closeDialog} sx={{color: '#0073AE', '&:hover': { color: '#FDB913' } }}>Close</Button>
      }
        { !hideUploadButton && <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{bgcolor: '#0073AE', '&:hover': { bgcolor: '#FDB913', color: '#000000' } }}
          disabled={uploadStart}
        >
          {uploadStart ? <CircularProgress size={24} /> : 'Upload'}
        </Button>}
      </DialogActions>
    </Dialog>
     { modalOpen && <InfoDialog 
      onOpen={modalOpen}
      onClose={handleClose}
  />}
  </>
  );
};

export default UploadNewDocument;