export const SecurityQuestionsStyles = {
    cardContainer: {
        width: '100%',
        boxShadow: 15,
    },

    submitButton: {
        bgcolor: '#0073AE',
        '&:hover': {
            bgcolor: '#FDB913',
            color: '#000000'
        },

    },

    cancelButton: {
        mt: 1,
        color: '#0073AE',
        '&:hover': {
            color: '#FDB913'
        },
    },
}