import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    allSentFileByAdminData:{},
    results:[],
   
}

const allSentFileByAdmin = createSlice({
    name:'sentFileByAdminData',
    initialState,
    reducers:  {
        SentFileByAdminSuccess: (state:any, action:any) => {
            state.results = action.payload.results
            state.allSentFileByAdminData = action.payload
        },
        SentFileByAdminFailed: (state) =>{
            state = state;
        }
    },
})

export const {SentFileByAdminSuccess, SentFileByAdminFailed} = allSentFileByAdmin.actions;
export default allSentFileByAdmin.reducer;