import React,{ useEffect, useState } from 'react'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,Tooltip,
    Typography,
  } from '@mui/material';
import { getAllExternalUser, resendInvitation, cancelInvitation } from '../../resources/api-constants';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useAppSelector } from '../../store/store';
import { useAppDispatch } from '../../store/store';
import { successResult } from '../../store/slice/getAllExternalUserSlice';

  interface InvitationData {
    id?: number;
    first_name: string;
    email: string;
    invited_date:string;
    apps: string;
    role:string;
  }
   

const PendingInvitation = () => {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {allInvitationData, results} = useAppSelector<any>(state => state.getAllExternalUserData)
  const dispatch = useAppDispatch();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0+1);
  };


  const getAllInvitationHandle = (pageNumber?:number,numberOfRows?:number) =>{
    const payload = {
        page:pageNumber ? pageNumber :page,
        page_size: numberOfRows ? numberOfRows :rowsPerPage,
        status:'pending',
    }
    getAllExternalUser(payload)
    .then(res => {
        //@ts-ignore
        dispatch(successResult(res))
    })
    .catch(error => {
        console.log("🚀 ~ useEffect ~ error: pending api", error)
    })
  }

  useEffect(() => {
    getAllInvitationHandle();
  },[page,rowsPerPage])

  const cancelConfirm = (id:number) => {
    Swal.fire({
        text:'Are you sure you want to cancel the Invitation?',
        icon:'question',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
    }).then(result =>{
        if(result.isConfirmed){
            cancelInvitaionHandle(id)
        }
    })
  }

  const cancelInvitaionHandle = (id:number) => { 
     const payload= {
        id,
     }
     cancelInvitation(payload)
     .then(res => {
       Swal.fire({
        text: "Invitation has been cancelled.",
        icon: "success",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
       getAllInvitationHandle(1,5)
     })
     .catch(error => {
       Swal.fire({
        text: "Error in canceling the invitation",
        icon: "error",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
     })
  }

  const resendInvitaionHandle = (id:number) => {
    const payload= {
        id,
     }
    resendInvitation(payload)
     .then(res => {
       Swal.fire({
        text: "Invitation has been successfully resent.",
        icon: "success",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
       getAllInvitationHandle(1,5)
     })
     .catch(error => {
       Swal.fire({
        text: error.response.data.error || "Error in resending the invitation",
        icon: "error",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
     })

  }
    

  return (
    <Box >
      <TableContainer component={Paper} sx={{width:'100%', overflow:'auto'}}>
        <Table aria-label="simple table" width='100%'>
          <TableHead >
            <TableRow >
            <TableCell sx={{fontWeight:'bold', fontSize:'11px', }}>Company Number</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}>Company Name</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}>User Name</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}>Email</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}>Apps</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}>Roles</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}>&nbsp;&nbsp;Actions</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}></TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}></TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}></TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}></TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}></TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}></TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'11px'}}></TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
         
            {results.length ? results?.map((invitationData: any) => (
              <TableRow key={invitationData?.id} >
                <TableCell ><Typography sx={{ fontSize:'11px', width:'6vw'}}>{invitationData?.companies?.length ? invitationData?.companies?.map((item:any, index:number) => (<Typography sx={{ fontSize:'11px', width:'7vw'}} key={index} variant="body1">{item?.company_number}</Typography>)) : <span style={{ fontSize: '11px', paddingLeft: '1rem' }}>-</span>}</Typography></TableCell>
                <TableCell ><Typography sx={{ fontSize:'11px', width:'8vw'}}>{invitationData?.companies?.length ? invitationData?.companies?.map((item:any,index:number) => (<Typography sx={{ fontSize:'11px', width:'8vw'}} key={index} variant="body1">{item?.name}</Typography>)) : <span style={{ fontSize: '11px', paddingLeft: '3rem' }}>-</span>}</Typography></TableCell>
                <TableCell ><Typography sx={{ fontSize:'11px', width:'8vw'}}>{invitationData?.first_name + ' '+ invitationData?.last_name}</Typography></TableCell>
                <TableCell  sx={{ fontSize:'11px'}}><Tooltip title={invitationData?.email} arrow><Typography sx={{ fontSize:'11px', width:'9vw',whiteSpace: 'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>{invitationData?.email}</Typography></Tooltip></TableCell>
                {/* <TableCell sx={{ fontSize:'11px'}}>{moment(invitationData?.invited_date).format("DD/MM/YYYY")}</TableCell> */}
                <TableCell sx={{ fontSize:'11px'}}><Typography sx={{ fontSize:'11px', width:'6vw'}}>{invitationData?.apps.map((item:any) => item.name).join(', ')}<br /></Typography></TableCell>
                <TableCell sx={{ fontSize:'11px'}}><Typography sx={{ fontSize:'11px', width:'10vw'}}>{invitationData?.member_role?.length ? invitationData?.member_role.map((item:any) => item.name).join(', ') : <span style={{ fontSize: '11px', paddingLeft: '1rem' }}>-</span>}<br /></Typography></TableCell>
                <TableCell sx={{ fontSize:'11px'}} colSpan={8}>
                    <Box >

                       {
                        invitationData.is_active ?
                            <Button
                                 variant="text"
                                 type='button'
                                 size='small'
                                  sx={{color:'#cb2c30', marginRight:'9px',fontSize:'10px','&:hover': {
                                    // color: '#FDB913',
                                   textDecoration: 'underline'
                                  }}}
                                 onClick={() =>cancelConfirm(invitationData?.id)}
                                  disableRipple
                             >
                                 Cancel Invitation
                             </Button>
                             : <Typography component='span' sx={{fontSize:'11px',marginRight:'18px', color:'#cb2c30',textAlign:'left' , '&:hover': {cursor:'no-drop'}}} ml={1}>Invitation Cancelled</Typography>
                        }

                        <Button
                            variant="outlined"
                            type='button'
                            size='small'
                             sx={{fontSize:'10px',
                              borderColor: '#FFBB00',
                              color: '#000000',
                              '&:hover': {
                              color: '#ffffff',
                              borderColor:'#FDB913',
                              bgcolor:'#0073ae',
                            }}}
                            
                            onClick={() =>resendInvitaionHandle(invitationData?.id)}
                             
                        >
                            Resend Invitation
                        </Button>
                    </Box>
                </TableCell>
              </TableRow>
             
            )) :
            <TableRow>
              <TableCell>Data Not Found</TableCell>
            </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow >
               <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={14}
                count={allInvitationData?.count || 0}
                rowsPerPage={allInvitationData?.page_size || 5}
                page={allInvitationData.current_page-1 || 0}
                slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                    },
                  }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> 
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PendingInvitation