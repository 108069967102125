import React from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    Typography,
    Checkbox,
    Backdrop,
    Tooltip,
  } from '@mui/material';   

const InfoDialog : React.FC<{ onOpen: any; onClose: any;}> = (props) => {
    const { onOpen, onClose } = props;

    const closeDialog = () => {
        onClose()
    }

  return (
    <Dialog
      open={onOpen}
      onClose={onClose}
      fullWidth={true}
      //slots={{ backdrop: CustomBackdrop }}
      disableEscapeKeyDown
      maxWidth='sm'
      scroll={'body'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
        <DialogTitle sx={{ fontWeight: 'bold' }}>
            Bulk Document Naming Guidelines:
        </DialogTitle>
        <DialogContent sx={{}}>
            <Typography>1. yyyy-mm-dd-#####-role-filename</Typography>
            <Typography>2. yyyy-mm-dd-#####-filename</Typography>
            <Typography>3. yyyy-mm-dd-role-tag-filename</Typography>
            <Typography>4. yyyy-mm-dd-tag-filename</Typography>
            <Typography>5. yyyy-mm-dd-filename</Typography>
            <Typography sx={{fontSize:'13px'}} mt={2}>Please name your files using the
following format to ensure your documents upload
successfully. For more information about naming
your files, please refer to the File Upload Guide
NTCA provided.</Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={closeDialog} sx={{ bgcolor: '#0073AE','&:hover': { bgcolor: '#FDB913', color: '#000000' } }}>Close</Button>
        </DialogActions>
    </Dialog>
  )
}

export default InfoDialog