import React, { useEffect,useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm,SubmitHandler,Controller, Control } from 'react-hook-form';
import { Box, MenuItem,Menu, Stack,InputLabel,Autocomplete } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getApps, addNewExternalUser,getAllExternalUser,getExternalUserRoles } from '../../resources/api-constants';
import Swal from 'sweetalert2';
import { getAllCompaniesArray } from '../../resources/api-constants';
import { successResult } from '../../store/slice/getAllExternalUserSlice';
import { useAppDispatch } from '../../store/store';
import  './ManageExternalUser.css';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
 // zIndex: 1000, 
}));

type Roles = {
    value:string;
    label:string;
}
interface IFormInput {
    firstName: string;
    lastName: string;
    email: string;
    // role:string;
    app:[];
    companies:[];
    externalUserRoles:[];
  }

  const roles = [
    {
      value: 'ADMIN',
      label: 'admin',
    },
    {
      value: 'MASTER',
      label: 'master',
    },
   
  ];

  type App = {
   id:string;
    name:string;
}[]

type Company = {
    id:string;
     name:string;
 }[]

const demoArray = [
    {id:'1', name:'abc'},
    {id:'2', name:'dddfdfd ee'},
    {id:'3', name:'fff'},
    {id:'4', name:'fdfd dfd'},
    {id:'5', name:'ddd'},
    {id:'6', name:'eee dfdfdd'},
    {id:'7', name:'cfffcc'},
    {id:'8', name:'ggdfd dfd'},
    
]

const InviteNewExternalUser = (props: { onOpen: any; onClose: any; companyId :string | null; getCompanyDetails:(pageNumber: any,numberOfRows: any) =>void }) => {
    const {onOpen, onClose, getCompanyDetails,companyId} = props;

    const { register, handleSubmit, formState: { errors }, setError, reset, control } = useForm<IFormInput>({
        defaultValues:{
            firstName:'',
            lastName:'',
            email:'',
            // role:'',
            app:[],   
            companies:[],  
            externalUserRoles:[],   
        }
    });

    const dispatch = useAppDispatch();
    
    const [role, setRole] = useState('');  

    const handleSetRole = (event: SelectChangeEvent) => {
        setRole(event.target.value);
    };
    const [appMenu, setAppMenu] = useState<App>([])
    const [externalUserRoles, setexternalUserRoles] = useState<App>([])
    const [apps, setApps] = useState('');  
    const [companiesMenu, setCompaniesMenu] = useState<Company>([])
    const handleSetApp = (event: SelectChangeEvent) => {
        setApps(event.target.value);
    };

    useEffect(()=>{
        getApps()
        .then(res =>{
            const appsArray = res.data.results.map((item:any) =>( {
                id: item.id,
                name: item.name,
              }))
            setAppMenu(appsArray)
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    useEffect(()=>{
        getAllCompaniesArray()
        .then(res =>{
            const companyArray = res?.data?.map((item:any) =>( {
                id: item.id,
                name: item.name,
              }))
              setCompaniesMenu(companyArray)
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    useEffect(()=>{
      getExternalUserRoles()
      .then(res => {
           const rollArray = res?.data?.map((item:any) =>( {
              	id: item.id,
              	name: item.name,
            	}))
            	setexternalUserRoles(rollArray)
      })
      .catch(error => {
          console.log(error)
      })
  },[])

    const onSubmit: SubmitHandler<IFormInput> = (data:IFormInput) => {
    //@ts-ignore
    const appArray = data.app.map(item => item.id)
    //@ts-ignore
    const memberRole = data.externalUserRoles.map(item => item.id)
    //@ts-ignore
    const companyArray = data.companies.map(item => item.id)
    const payload = {
        first_name:data.firstName,
        last_name:data.lastName,
        role:'company_user',
        email:data.email.toLowerCase(),
        apps:appArray,
        companies:companyArray,
        member_role:memberRole,
    }
    
        addNewExternalUser(payload)
        .then((res) => {
            if(res.status == 200){
              closeDialog();
              Swal.fire({
                text: res?.data?.message,
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              }).then((result) => {
                if (result.isConfirmed) {
                    const payload = {
                        page:1,
                        page_size:5,
                        status:'accepted',
                    }
                    getAllExternalUser(payload)
                    .then(res => {
                        //@ts-ignore
                        dispatch(successResult(res))                        
                    })
                    .catch(error => {
                        console.log("🚀 ~ useEffect ~ error: pending api", error)
                    })               
                 
                }
              });
            }
            if(res.status == 201){
                closeDialog();
                Swal.fire({
                  text: "Invitation has been sucessfully sent.",
                  icon: "success",
                  customClass: {
                    confirmButton: 'custom-confirm-button',
                    cancelButton: 'custom-cancel-button',
                    htmlContainer : 'custom-html-container',
                   }
                }).then((result) => {
                  if (result.isConfirmed) {
                      const payload = {
                          page:1,
                          page_size:5,
                          status:'pending',
                      }
                      getAllExternalUser(payload)
                      .then(res => {
                          //@ts-ignore
                          dispatch(successResult(res))                          
                      })
                      .catch(error => {
                          console.log("🚀 ~ useEffect ~ error: pending api", error)
                      })               
                   
                  }
                });
            }
            
        })
        .catch(error => {
            closeDialog();
            Swal.fire({
                text: error.response.data.error,
                icon: "error",
                customClass:{
                  popup:'custom-swal',
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                }
              });
        })
       
        
    }

    const closeDialog = () =>{
        reset();
        onClose()
    }

  return (
    <StyledDialog
        open={onOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          error:errors,
        }}
      >
        <DialogTitle sx={{pb: '5px !important'}}><b>Add New Company User</b></DialogTitle>
        <DialogContent>
        <Box
        >
        <Stack direction='row' justifyContent='space-between' m={1} ml={0} alignItems='center' width='100%'>
        <Controller
                name='companies'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={companiesMenu}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={field.value || []}
                    onChange={(_, value) => field.onChange(value)}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label='Search Companies'
                      error={!!errors.companies}
                      helperText={errors.companies ? errors.companies.message : ''}
                      />
                    )}
                    sx={{ width: '48%' }}
                    />
                )}
            />
            <Controller
                name='app'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={appMenu}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={field.value || []}
                    onChange={(_, value) => field.onChange(value)}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label='Application Access'
                      error={!!errors.app}
                      helperText={errors.app ? errors.app.message : ''}
                      />
                    )}
                    sx={{ width: '48%' }}
                    />
                )}
            />
        </Stack>
        <Stack direction='row' justifyContent='space-between' m={1} ml={0} alignItems='center' width='100%'>
            <TextField
                variant="outlined"
                margin="normal"
                id="firstName"
                label="First Name"
                autoComplete="firstName"
                size="small"
                sx={{
                    width:'48%',
                }}
                {...register('firstName', {
                  required: 'First Name is required',
                  pattern: {
                    value:/^(?! )[a-zA-Z ]*$/,
                    message: 'Special characters, numbers and only space are not allowed',
                  },
          })}
          error={!!errors.firstName}
          helperText={errors.firstName ? errors.firstName.message : ''}
        />
        <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                autoComplete="lastName"
                size="small"
                sx={{
                    width:'48%',
                   
                }}
                {...register('lastName', {
                  required: 'Last Name is required',
                  pattern: {
                    value:/^(?! )[a-zA-Z ]*$/,
                    message: 'Special characters, numbers and only space are not allowed',
                  },
          })}
          error={!!errors.lastName}
          helperText={errors.lastName ? errors.lastName.message : ''}
        />
        </Stack>
        <Box pl={1} width='100%'>
       
          </Box>

          <Stack direction='row' justifyContent='space-between'  alignItems='center' mt={2} width='100%' >
          <TextField
            variant="outlined"
            // margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            size="small"
            sx={{
                width:'48%',
            }}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Enter a valid Email address',
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />
          <Controller
                name='externalUserRoles'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={externalUserRoles}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={field.value || []}
                    onChange={(_, value) => field.onChange(value)}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label='Search Roles'
                      error={!!errors.externalUserRoles}
                      helperText={errors.externalUserRoles ? errors.externalUserRoles.message : ''}
                      />
                    )}
                    sx={{ width: '48%' }}
                    />
                )}
            />
      </Stack>
      </Box>
      
         
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} sx={{
            '&:hover': {
              color: '#FDB913',
              
            },
            color: '#0073AE'
          }}>Cancel</Button>
          {/* <Button type="submit" variant='contained'>Invite</Button> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              '&:hover': {
                color: '#000000',
                bgcolor:'#FDB913',
              },
              bgcolor: '#0073AE'
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </StyledDialog>
  )
}

export default React.memo(InviteNewExternalUser)