import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { format, formatDistanceToNow, isToday, isYesterday } from 'date-fns';
import { patchReadNotification } from '../resources/api-constants';
import { useState } from 'react';

interface NotificationCardProps {
    id: string,
    title: string,
    description: string,
    date: Date | any,
    isMarkedRead: boolean,
    getAllNotificationsHandle: any,
    CustomDialogBox: any
}

const convertDate = (dateString: string | number | Date | any) => {
    const date = new Date(dateString);

    if (isToday(date)) {
        return formatDistanceToNow(date, { addSuffix: true });
    } else if (isYesterday(date)) {
        return 'Yesterday';
    } else {
        return format(date, 'do MMMM, yyyy');
    }
};

const NotificationCard: React.FC<NotificationCardProps> = ({ CustomDialogBox , getAllNotificationsHandle, id, title, description, date, isMarkedRead }) => {

    const [modalOpen, setModalOpen] = useState(false);

    const patchReadNotificationHandle = () => {

        const payload = {
            id: id
        };

        patchReadNotification(payload)
            .then(res => {
                getAllNotificationsHandle();
            })
            .catch(error => {
                console.log("🚀 ~ useEffect ~ error: pending api", error)
            })
    }


    const handleMarkReadNotification = () => {
        patchReadNotificationHandle();
    }

    
    const handleOpen = () => {
        if(!isMarkedRead){
            handleMarkReadNotification();
        }
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Card sx={{ backgroundColor: !isMarkedRead ? '#CCE2F0' : '#fffff' }}>
                <Grid container>
                    <Grid item xs={0.4} textAlign={"end"} alignContent={"center"}>
                        {
                            !isMarkedRead &&
                            <FiberManualRecordIcon sx={{ color: '#0070B4' }} fontSize="small" />
                        }
                    </Grid>
                    <Grid item xs={11.6}>
                        <CardHeader
                            action={
                                !isMarkedRead &&
                                <Button onClick={handleMarkReadNotification} variant="text" sx={{ textTransform: 'none', fontWeight: 'bold',  color: '#0070B4' }} startIcon={<MarkEmailReadOutlinedIcon sx={{ color: '#707070' }} />}>
                                    Mark as read
                                </Button>
                            }
                            title={
                                <Typography fontWeight={"bold"} fontSize={"16px"}>
                                    {title}
                                </Typography>
                            }
                        />
                        <CardContent sx={{ paddingTop: '0px', fontSize: '16px' }}>
                            {description.substring(0, 80) + '...'}
                        </CardContent>
                        <CardActions sx={{ paddingTop: '0px' }} disableSpacing>
                            <Button onClick={handleOpen} variant="text" sx={{ fontWeight: 'bold', color: '#0070B4' }} endIcon={<ArrowForwardIcon sx={{ color: '#0070B4'}}/>}>
                                View Details
                            </Button>

                            <Typography marginLeft={"auto"} marginRight={1}>
                                {
                                    convertDate(date)
                                }
                            </Typography>
                        </CardActions>
                    </Grid>
                </Grid>
            </Card>

            {modalOpen && <CustomDialogBox
                title={title}
                description={description}
                onOpen={modalOpen}
                onClose={handleClose}
            />}
        </>
    )
}

export default NotificationCard;