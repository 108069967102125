import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import Swal from 'sweetalert2';

const stagingEnv = 'https://ntca-42467-staging.botics.co';
const prodEnv =  'https://solitary-recipe-42467.botics.co/'

const requestConfig: AxiosRequestConfig = {
    baseURL:  prodEnv,
    timeout: 5000,
  };

export const CustomAxios = axios.create(requestConfig)


CustomAxios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            Swal.fire({
                text: "Session Time out. You have been logged out of your account.",
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
            }).then(res => {
                if(res.isConfirmed){
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    window.location.href = '/login';
                  }
           
            })
            
          }
        return Promise.reject(error)
    }
)

CustomAxios.interceptors.request.use(
    (config) => {
       let token = localStorage.getItem("token");
       config.headers["Authorization"] = token ? "Token " + token : null;
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default CustomAxios
