import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem,Box, Button, Stack, SvgIcon } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NtcaLogo from '../assets/NtcaLogo.png';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import {ROUTES} from '../resources/routes-constants'
import { useAuth } from '../resources/AuthProvider'
import { NavLink, useNavigate } from 'react-router-dom'
import { getAllNotifications, logout } from '../resources/api-constants';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { ReactComponent as NotificationsAlertIcon } from '../assets/NotificationIcon.svg';
import { ReactComponent as SelectedNotificationsAlertIcon } from '../assets/_NotificationIcon.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAppDispatch, useAppSelector } from '../store/store';
import { NotificationSuccess } from '../store/slice/getAllNotificationSlice';


const Header: React.FC = () => {
  const userData = localStorage.getItem('user') || '';
  const user = JSON.parse(userData)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const {allNotificationData, page, rowsPerPage} = useAppSelector<any>(state => state.getAllNotificationData)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {setToken} = useAuth()
  const navigate = useNavigate()

  const logoutHandler = () => {
      
      logout()
      .then(res => {
        setToken('')
        navigate(ROUTES.LOGIN_ROUTE,{ replace: true })
        window.location.reload();
        localStorage.removeItem('user')
      })
      setToken('')
      navigate(ROUTES.LOGIN_ROUTE,{ replace: true })
      window.location.reload();
      localStorage.removeItem('user')
   };

   const navigateToProfile = () => {
    navigate(ROUTES.USER_PROFILE)
   }

   const navigateToSettings = () => {
    navigate(ROUTES.ACCOUNT_SETTINGS)
   }

   const getAllNotificationsHandle = useCallback(
    (pageNumber?: number, numberOfRows?: number) => {
      const payload = {
        page: pageNumber || page,
        page_size: numberOfRows || rowsPerPage,
      };

      getAllNotifications(payload)
        .then((res:any) => {
          dispatch(NotificationSuccess(res));
        })
        .catch((error) => {
          console.error('🚀 ~ useEffect ~ error: pending api', error);
        });
    },
    [dispatch, page, rowsPerPage]
  );

  useEffect(() => {
    // Call the function immediately
    getAllNotificationsHandle();

    // Set up the interval
    const intervalId = setInterval(() => {
      getAllNotificationsHandle();
    }, 60000); // 60000ms = 1 minute

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [getAllNotificationsHandle]);

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{bgcolor:'#FFFFFF'}}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <Box
            component="img"
            alt="NTCA Logo"
            src={NtcaLogo}
            m={1}
            width='159px'
            height='40px'
        />
        </Typography>
        <NavLink
          to={{pathname: ROUTES.NOTIFICATIONS}}
          style={({ isActive }) => ({
              color: isActive ? '#0073ae' : '#000000', // Change 'blue' to your desired active color
              textDecoration: 'none',
              marginRight: '30px'
          })}
          >
          {({ isActive }) => (
                <Stack direction="row" spacing={1} alignItems="center">
                    {
                        isActive 
                        ? (allNotificationData?.unread_count 
                            ? <SvgIcon sx={{ alignSelf: 'center' }} fontSize='small' component={SelectedNotificationsAlertIcon} inheritViewBox />
                            : <NotificationsNoneIcon />) 
                        : (allNotificationData?.unread_count 
                            ? <SvgIcon sx={{ alignSelf: 'center' }} fontSize='small' component={NotificationsAlertIcon} inheritViewBox />
                            : <NotificationsNoneIcon />)
                    }
                    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                        Notifications
                    </Typography>
                </Stack>
            )}
        </NavLink>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <AccountCircle fontSize='large' />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                width:'170px',
                // mt: 1,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Typography sx={{ fontSize:'11px'}} noWrap>
              <Box component="span" sx={{ fontWeight: 'bold' }}>{user?.full_name || 'User'}</Box> <br />
                {user?.role}<br />
                {user?.email}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={navigateToSettings}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
                Settings
            </MenuItem>
            <MenuItem onClick={navigateToProfile}>
            <ListItemIcon>
            <AccountCircleIcon fontSize="small"/>
            </ListItemIcon>
               Profile
            </MenuItem>
            <Divider />
            <MenuItem onClick={logoutHandler}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
                LOG OUT
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;