import { createSlice } from "@reduxjs/toolkit";
import loginUser from "../actions/loginAction";
import { LoginData, LoginState } from "../../resources/types";


const initialState  = {
    loading:false,
    loginData:  {},
    error:{},
}

const loginSlice = createSlice({
    name: 'login',
    initialState : initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false
            state.loginData = action.payload
            state.error = ''
        })
        builder.addCase(loginUser.rejected, (state,action) => {
            state.loading = false
            state.loginData = {}
            state.error = action.error || ''
        })
    },
})

export default loginSlice.reducer;