import { Box, Button, Card, CardContent, CardHeader, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import NtcaLogo from '../../assets/NtcaLogo.png';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { SecurityQuestionsStyles } from './SecurityQuestionsStyles';
import { ROUTES } from "../../resources/routes-constants";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { addSecurityQuestionsForUser, getAllSecurityQuestions } from "../../resources/api-constants";
import Swal from "sweetalert2";

interface IFormInput {
    securityQuestions: { question: string; answer: string; }[];
}

type SecurityQuestion = {
    id: string;
    question: string;
}[]

const SecurityQuestions = () => {

    const [securityQuestions, setSecurityQuestions] = useState<SecurityQuestion>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const email = searchParams.get('email')
    const userName = searchParams.get('userName');

    const { register, handleSubmit, formState: { errors }, setError, reset, control } = useForm<IFormInput>({
        defaultValues: {
            securityQuestions: [
                { question: '', answer: '' },
                { question: '', answer: '' },
                { question: '', answer: '' }
            ]
        }
    });

    const confirmCancel = () => {
        Swal.fire({
            text: "Are you sure you want to cancel setting up your security questions?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer: 'custom-html-container',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    text: "Security question setup has been cancelled.",
                    icon: "info",
                    customClass: {
                        confirmButton: 'custom-confirm-button',
                        cancelButton: 'custom-cancel-button',
                        htmlContainer: 'custom-html-container',
                    }
                });
                navigate(ROUTES.LOGIN_ROUTE, { replace: true })
            }

        });
    }

    const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
        console.log(data);

        const payload = {
            email: email ? email : userName,
            all_questions: data?.securityQuestions,
        }

        addSecurityQuestionsForUser(payload)
            .then((res) => {
                Swal.fire({
                    text: res.data.message || "Your security questions have been successfully added.",
                    icon: "success",
                    customClass: {
                        confirmButton: 'custom-confirm-button',
                        cancelButton: 'custom-cancel-button',
                        htmlContainer: 'custom-html-container',
                    }
                }).then((res) => {
                    navigate(ROUTES.LOGIN_ROUTE, { replace: true });
                })
            })
            .catch(error => {
                Swal.fire({
                    text: error.response.data.error || 'Failed to add security questions. Please try again.',
                    icon: "error",
                    customClass: {
                        popup: 'custom-swal',
                        confirmButton: 'custom-confirm-button',
                        cancelButton: 'custom-cancel-button',
                        htmlContainer: 'custom-html-container',
                    }
                });
            })
    }

    useEffect(() => {
        getAllSecurityQuestions()
            .then(res => {
                const SecurityQuestionsArray = res?.data?.map((item: any) => ({
                    id: item.id,
                    question: item.question,
                }))
                setSecurityQuestions(SecurityQuestionsArray);
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (
        <>
            <Container component="main" maxWidth="sm">
                <Box textAlign={"center"} mt={5} mx={5}>
                    <Box
                        component="img"
                        alt="NTCA Logo"
                        src={NtcaLogo}
                        mb={5}
                    />

                    <Card sx={SecurityQuestionsStyles.cardContainer}>
                        <CardHeader title={
                            <Typography component="h1" variant="h5" gutterBottom sx={{
                                color: '#475260'
                            }}>
                                Set Your Security Questions
                            </Typography>
                        } />

                        <CardContent sx={{ textAlign: 'left', mx: 2 }}>
                            <Box component="form"
                                onSubmit={handleSubmit(onSubmit)}
                                noValidate>

                                {Array.from({ length: 3 }, (_, index) => (
                                    <Grid container spacing={1} key={index}>
                                        <Grid item xs={12}>
                                            <Typography fontWeight={"bold"}>
                                                Question {index + 1}:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} mt={1}>
                                            <FormControl error={!!errors?.securityQuestions?.[index]?.question} fullWidth>
                                                <InputLabel id={`security-question-${index + 1}`}>Select question {index + 1}</InputLabel>
                                                <Controller
                                                    name={`securityQuestions.${index}.question`}
                                                    rules={{ required: 'Please select a security question' }}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                labelId={`security-question-${index + 1}`}
                                                                id={`security-question-select-${index}`}
                                                                label={`Select question ${index + 1}`}
                                                                {...field}
                                                            >
                                                                {securityQuestions.map((question) => (
                                                                    <MenuItem key={question?.id} value={question?.id}>
                                                                        {question.question}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </>
                                                    )}
                                                />
                                                <FormHelperText>
                                                    {errors?.securityQuestions?.[index]?.question?.message}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name={`securityQuestions.${index}.answer`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id={`security-answer-${index}`}
                                                        label="Type your answer here..."
                                                        {...register(`securityQuestions.${index}.answer`, {
                                                            required: 'Please provide an answer to the selected question.',
                                                            pattern: {
                                                                value: /^(?!\s*$).+/,
                                                                message: 'The field cannot contain only spaces.',
                                                            }
                                                        })}
                                                        error={!!errors?.securityQuestions?.[index]?.answer}
                                                        helperText={errors?.securityQuestions?.[index]?.answer?.message}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}

                                <Box mt={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={SecurityQuestionsStyles.submitButton}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="text"
                                        fullWidth
                                        sx={SecurityQuestionsStyles.cancelButton}
                                        onClick={confirmCancel}
                                        disableRipple
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </>
    )
}

export default SecurityQuestions;