import React, {useState, useEffect} from 'react'
import { getCompanyDetails,deleteExternalUserFromCompany } from '../../resources/api-constants'
import { useSearchParams } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,Tooltip,
    Typography,Container,
  } from '@mui/material';
import Swal from 'sweetalert2';
import InviteCompanyMember from './InviteCompanyMember';

const CompanyDetails = () => {

    const [searchParams] = useSearchParams();
    const companyNumber = searchParams.get('companyNumber');
    const companyName = searchParams.get('companyName');
    const adminName = searchParams.get('adminName');
    const adminEmail = searchParams.get('adminEmail');
    const companyId = searchParams.get('companyId');

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [companyData, setCompanyData] = useState<any>()
    const [results, setResult] = useState<any>()
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0+1);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => {
      setModalOpen(true);
    };
    const handleClose = () => {
      setModalOpen(false);
    };

    const getCompanyDetailsHandle = (pageNumber?:number,numberOfRows?:number,) => {
        const  payload ={
            id: companyId,
            page:pageNumber ? pageNumber :page,
            page_size: numberOfRows ? numberOfRows :rowsPerPage,
            }
            getCompanyDetails(payload)
                .then(res => {
                    setCompanyData(res?.data)
                    setResult(res?.data)
                })
                .catch(error => {
                    console.log("🚀 ~ useEffect ~ error:", error)
                })
    }

    
    useEffect(() => {
        getCompanyDetailsHandle()
    },[page,rowsPerPage])

    const deleteUserConfirmHandle = (id:number) => {
        Swal.fire({
            text:'Are you sure you want to delete this User?',
            icon:'question',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
        }).then(result =>{
            if(result.isConfirmed){
              deleteUserHandle(id)
            }
        })
      }
    
      const deleteUserHandle = (id:number) => { 
         const payload= {
            id,
         }
         deleteExternalUserFromCompany(payload)
         .then(res => {
           Swal.fire({
            text: "User has been successfully deleted.",
            icon: "success",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
           })
           getCompanyDetailsHandle(1,5)
         })
         .catch(error => {
           Swal.fire({
            text: "Error in deleting the User.",
            icon: "error",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
           })
         })
      }
    
    
            

  return (
    <>
    <Container component="main" sx={{ padding:'0px', marginTop:'15px', marginLeft:'10px'}} maxWidth='xl'>
    <Stack spacing={2}>
       <Box>
       <Stack  direction='column'  width='70%'>
            <Box>
                <Typography variant='h4' sx={{fontSize:'34px'}}>
                    Company Details
                </Typography>
            </Box>
            <Stack direction='column' mt={3} width='100%'>
               <Stack direction='row' justifyContent='space-between' width='100%'>
                    <Box width='50%'>
                        <Typography sx={{fontSize:'13px'}}>Company Number:</Typography>
                        <Typography sx={{fontWeight:'bold'}}>{results?.company?.company_number}</Typography>
                    </Box>
                    <Box width='50%'>
                        <Typography sx={{fontSize:'13px',}}>Company Name:</Typography>
                        <Typography sx={{fontWeight:'bold'}}>{results?.company?.name}</Typography>
                    </Box>
                </Stack> 
                {/* <Stack direction='row' justifyContent='space-between' mt={5} width='100%'>
                    <Box width='50%'>
                        <Typography sx={{fontSize:'13px'}}>Company Administrator Name:</Typography>
                        <Typography sx={{fontWeight:'bold'}}>{results?.company?.admin?.full_name}</Typography>
                    </Box>
                    <Box width='50%'>
                        <Typography sx={{fontSize:'13px',}}>Company Administrator Email:</Typography>
                        <Typography sx={{fontWeight:'bold'}}>{results?.company?.admin?.email}</Typography>
                    </Box>
                </Stack>   */}
            </Stack>
       </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={5}>
            <Typography variant='h6'>
            Users
            </Typography>
            <Button size='small' variant='contained' sx={{fontSize:'12px', '&:hover': {
              bgcolor: '#FDB913',
              color:'#000000'
            },
            bgcolor: '#0073AE'
            }} onClick={handleOpen}>Invite New User</Button>
        </Stack>

       </Box>
       <Box>
       <TableContainer component={Paper} >
    <Table aria-label="simple table">
      <TableHead >
        <TableRow >
          
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> Name</TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> Email</TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> Roles</TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
     
        {results?.company_users?.results.length ? results?.company_users.results.map((user: any) => (
          <TableRow key={user?.id} >
            <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', width:'10vw'}}>{user.user.full_name}</Typography></TableCell>
            <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{user?.user?.email}</Typography></TableCell>
            {/* <TableCell  sx={{ fontSize:'12px'}}><Tooltip title={user?.email} arrow><Typography sx={{ fontSize:'12px', }}>{user?.user?.role}</Typography></Tooltip></TableCell> */}
            <TableCell sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{user?.member_role.map((item:any) => item.name).join(', ')}<br /></Typography></TableCell>

            <TableCell sx={{ fontSize:'12px'}} colSpan={9}>
                <Stack direction='row'>
                        <Button
                             variant="text"
                             type='button'
                             size='small'
                              sx={{color:'#cb2c30', marginRight:'10px',fontSize:'11px', '&:hover': {
                                //color: '#FDB913',
                                textDecoration: 'underline'
                              },}}
                             onClick={() => deleteUserConfirmHandle(user?.id)}
                              disableRipple
                         >
                             Remove User
                         </Button>                       
                </Stack>
            </TableCell>
          </TableRow>
         
        )) :
        <TableRow>
          <TableCell>Data Not Found</TableCell>
        </TableRow>
        }
      </TableBody>
      <TableFooter>
        <TableRow>
           <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={8}
            count={companyData?.company_users?.count || 0}
            rowsPerPage={companyData?.company_users?.page_size || 5}
            page={companyData?.company_users?.current_page-1 || 0}
            slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                },
              }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> 
        </TableRow>
      </TableFooter>
    </Table>
  </TableContainer>
       </Box>
    </Stack>
</Container>
{ modalOpen && <InviteCompanyMember 
        onOpen={modalOpen}
        onClose={handleClose}
        companyId={companyId}
        getCompanyDetails = {(pageNumber?:number,numberOfRows?:number) => getCompanyDetailsHandle(pageNumber,numberOfRows)}
    />}
</>
  )
}

export default CompanyDetails