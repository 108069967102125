import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Box, Typography, Container, Card, CardContent } from '@mui/material';
import NtcaLogo from '../../assets/NtcaLogo.png'
import { createSearchParams, useNavigate, useSearchParams,useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { maskPhoneNumber } from '../../utility/utilFunctions';
import { ROUTES } from '../../resources/routes-constants';
import { verifyOTP } from '../../resources/api-constants';
import { sendOTP,loginAfterVerificaion,reSendOtpOnEmail } from '../../resources/api-constants';
import { OtpStyles } from './OtpStyles';
import { useAuth } from '../../resources/AuthProvider'

interface FormInputs {
  otp: string[];
}

const OTP_LENGTH = 6;

const EmailOTP: React.FC = () => {
  const { control, handleSubmit, setValue, getValues, watch } = useForm<FormInputs>({
    defaultValues: {
      otp: ['', '', '', '', '', ''],
    },
  });

  const otpFields = watch('otp');
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [timer, setTimer] = useState(60);
  const [isRunning, setIsRunning] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('email')
  const lat = searchParams.get('lat') || '';
  const lng = searchParams.get('lng') || '';

  const navigate = useNavigate();
  const location = useLocation();
  const { setToken} = useAuth();
  const from = location.state?.from?.pathname || ROUTES.DOCUMENT_MANAGEMENT;

  useEffect(() => {
    const handleBeforeUnload = (event:any) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); 

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
  const otp = data?.otp.join('');
 
    if(otp.length < 6){
        Swal.fire({
            text: "Please enter the correct OTP.",
            icon: "error",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          })
          return;
    }
    const payload = {
        email: email ? email.toLowerCase() : '',
        otp:otp,
        // latitude: 40.7128,//userLocation.lat,
        // longitude: -74.0060, //userLocation.lng,
        latitude:lat,
        longitude:lng,
    }

    loginAfterVerificaion(payload)
    .then(res => {

        if(res?.data?.is_external_user){
          Swal.fire({
            text: 'Your account has been successfully verified. Please proceed with M.F. Authentication.',
            icon: "success",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          }).then(()=>{
            const params = {email:email ? email.toLowerCase() : '',lat:lat, lng: lng}
            navigate({
                pathname: `${ROUTES.MFA_VERIFICATION}`,
                //@ts-ignore
                search: `?${createSearchParams(params)}`,
            });
          })
        }

        if(res?.data?.token){
            Swal.fire({
                text: 'Your account has been successfully verified.',
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              })
          localStorage.setItem('user',JSON.stringify(res?.data?.user))
            setToken(res.data.token)
            navigate(from, { replace: true });
        }

        else if(res?.data?.is_account_locked){
            navigate(ROUTES.UNLOCK_ACCOUNT)
        }  
    })
    .catch(error => {
        Swal.fire({
            text: error.response.data.error,
            icon: "error",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          })
    })
  };

  const resendToken = () => {
    const userMail = email ? email.toLowerCase() : '';
    const payload = {
      email : userMail,
    }
    
    reSendOtpOnEmail(payload)
    .then(res => {
        console.log("🚀 ~ resendToken ~ res:", res)
      handleRestart()
        Swal.fire({
            text: res?.data?.data?.message,
            icon: "success",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          })
    })
    .catch(error => {
        Swal.fire({
            text: error.response.data.error || 'network error' ,
            icon: "error",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          })
    })
    
  }

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleFocus = (event: any, index: number) => {
    const value = event.target.value;
    if (value.length >= 1 && index < OTP_LENGTH - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === 'Backspace' && !getValues(`otp.${index}`) && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;
    if (isRunning && timer > 0) {
        intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
    }

    if (timer === 0) {
        setIsRunning(false);
    }

    return () => clearInterval(intervalId);
}, [isRunning, timer]);

const handleRestart = () => {
  setTimer(60);
  setIsRunning(true);
};

  const confirmCancel = () => {
    Swal.fire({
        text: "Are you sure you want to cancel the Account verification?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            text: "Account verification cancelled!",
            icon: "info",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          });
          navigate(ROUTES.LOGIN_ROUTE,{replace: true})
        }
        
      });
  }

  return (
    <Container component="main" sx={OtpStyles.container}>
      <Box
       sx={OtpStyles.boxContainer}
      >
         <Box
                component="img"
                alt="NTCA Logo"
                src={NtcaLogo}
                mb={5}
            />

        <Card sx={OtpStyles.cardContainer}>
            <CardContent>
              <Box
                sx={OtpStyles.cardBox}
              >       
        <Typography component="h1" variant="h5" gutterBottom sx={{
            color:'#475260'
        }}>
          Account Verification
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={OtpStyles.heading}>
        {`Enter the verification code sent to ${email}`}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Box sx={OtpStyles.formContainer}  mb={5}>
            {otpFields.map((_, index) => (
              <Controller
                key={index}
                name={`otp.${index}` as const}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    variant="outlined"
                    onChange={(e) => {
                      field.onChange(e);
                      handleFocus(e, index);
                    }}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center' },
                      
                    }}
                    sx={{ width: '3rem', mx: 0.5 }}
                  />
                )}
              />
            ))}
          </Box>
          <Box sx={{alignItems: 'center',}}>
          <Typography sx={OtpStyles.subHeaning}>
          Didn't receive the verification code?
          </Typography>
        
          {timer > 0 ?
          <Typography sx={{textAlign:'center'}}>
            {timer}
          </Typography>

           :
          <Button variant="text" 
                  onClick={resendToken}
                  fullWidth sx={OtpStyles.resendButton}>
                
                Resend Code

                </Button>
            }
          </Box>
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={timer > 0 ? false : true}
            sx={OtpStyles.submitButton}
          >
            Submit
          </Button>
          <Button
              onClick={confirmCancel}
              variant="text"
              fullWidth
              sx={OtpStyles.cancelButton}
               disableRipple
          >
                  Cancel
          </Button>
        </Box>
        </Box>
        </CardContent>
          </Card>
      </Box>
    </Container>
  );
};

export default EmailOTP;