
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/store'
import forgotUserPassword from '../store/actions/forgotPasswordAction';
import { useNavigate, useLocation, } from 'react-router-dom';
import { useAuth } from '../resources/AuthProvider';
import { ROUTES } from '../resources/routes-constants';
import { useForm, SubmitHandler } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import NtcaLogo from '../assets/NtcaLogo.png';
import { reqForgotPassLink } from '../resources/api-constants';
import {Dialog,DialogContent,DialogTitle} from '@mui/material'
import Swal from 'sweetalert2';


export const EmailSentScreen = ({email}:any) => {

    const resendEmail = () => {
        const payload = {
            email,
        }
        reqForgotPassLink(payload)
        .then((response) => {
            Swal.fire({
              text: `Password reset link has been resent to ${email}.`,
              icon: "success",
              customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer : 'custom-html-container',
               } 
            })
          } )
          .catch(error => {
            Swal.fire({
              text: error.response.data.error,
              icon: "error",
              customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer : 'custom-html-container',
               }
            })            
          })
    }

  return (
    <>
    <Container component="main" sx={{
        width: "520px",
        height: '409px'
  }} 
  >
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
        <Box
            component="img"
            alt="NTCA Logo"
            src={NtcaLogo}
            mb={5}
        />
    <Card sx={{ width: '100%', boxShadow: 15 }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >    

      <Typography component="h1" variant="h5" gutterBottom sx={{
        color:'#475260',
        fontWeight: 'bold',
      }}>
        Password Reset Link Sent
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{
        color:'#475260',
        fontSize: 14,
        textAlign:'center',
        width:'80%'
      }}>
        We have sent a password reset link to
      </Typography>
      <Typography variant='subtitle2' mb={4} sx={{
        fontWeight:'bold',
        color:'#475260',
      }}>
        {email}
      </Typography>

      <Typography variant="subtitle1" mb={4} gutterBottom sx={{
        color:'#475260',
        fontSize: 14,
        textAlign:'center',
        width:'80%'
      }}>
        Don't receive the email?
        <Button
          onClick={resendEmail}
              variant="text"
              sx={{
                  color:'#0073AE',
                  textTransform: 'capitalize',
                  '&:hover': {
                    color:'#FDB913'
                  },
               }}
               disableRipple
          >
                  Click here to resend the link.
          </Button>
      </Typography>
      
    </Box>
    </CardContent>
      </Card>

    </Box>
  </Container>
  </>
  )
}
