export const OtpStyles ={
    container:{
        width: "520px",
        height: '409px'
    },

    boxContainer:{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },

    cardBox:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },

    cardContainer:{ width: '100%', boxShadow: 15 },
    heading:{
        fontSize:'12px',
        color:'#475260',
    },  

    formContainer:{ display: 'flex', justifyContent: 'space-between' },
    subHeaning: {textAlign:'center', color:'#475260'},
    resendButton:{
        textTransform: 'capitalize',
        color:'#0073AE',
        textAlign:'center',
        '&:hover': {
          color:'#FDB913',
          background:'none',
      },
    },
    submitButton:{ mt: 3, mb: 2,
      '&:hover': {
        bgcolor: '#FDB913',
        color:'#000000'
      },
      bgcolor:'#0073AE'
     },
    cancelButton: { mt: 1,
        color:'#0073AE',
        '&:hover': {
          color: '#FDB913',
        },
     },

}