export const routeVariables = {
    email:'email',
    invitationToken: "code",
  }

export const ROUTES = {
    //HOMEPAGE_ROUTE: '/',
    ABOUTPAGE_ROUTE:'/dashboard/about',
    LOGIN_ROUTE:'/login',
    SIGNUP_ROUTE:'/signup',
    REQ_FORGOT_PASSWORD_ROUTE:'/reqForgotPassword',
    FORGOT_PASSWORD_ROUTE:'/forgotpassword',
    UNLOCK_ACCOUNT:'/unlockAccount',
    DASHBOARD:'/dashboard',
    OTP_SCREEN:'/otp',
    RETRIEVEINVITAION_SCREEN:'/retrieveInvitaion',
    SECURITY_QUESTIONS:'/securityQuestions',
    SECURITY_QUESTION_VERIFICATION:'/securityQuestionVerification',
    EMAIL_OTP:'/emailOtp',
    PAGE_NOT_FOUND: '/pageNotFound',
    MFA_VERIFICATION: '/mfaVerification',
    SSO: '/sso',
    SSO_UNAUTHORIZED: '/ssounauthorized',

    MANAGE_INTERNAL_USER: '/dashboard/manageInternalUser',
    MANAGE_COMPANY:'/dashboard/manageCompany',
    COMPANY_DETAILS:'/dashboard/companyDetails',
    MANAGE_EXTERNAL_USER: '/dashboard/manageExternalUser',
    EXTERNAL_USER_DETAILS:'/dashboard/externalUserDetails',
    USER_PROFILE:'/dashboard/userProfile',
    DOCUMENT_MANAGEMENT:'/dashboard/documentManagement',
    FILE_TRANSFER:'/dashboard/fileTransfer',
    ACCOUNT_SETTINGS:'/dashboard/settings',
    FAQ:'/dashboard/faq',
    CONTACT_US:'/dashboard/contactus',
    NOTIFICATIONS: '/dashboard/notifications',
    ACTIVITY_LOG: '/dashboard/activityLog',
    MANAGE_USERS_ACCESS: '/dashboard/manageUsersAccess'
}
