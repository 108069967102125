import { configureStore,ThunkDispatch,UnknownAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import demoCountReducer from './slice/demoCountSlice';
import userReducer from './slice/userSlice';
import loginReducer from './slice/loginSlice';
import signUpReduer from './slice/signUpSlice';
import reqforgotPasswordLinkReducer from './slice/reqforgotPasswordLinkSlice';
import forgotPasswordReducer from './slice/forgotPasswordSlice';
import UnlockAccountReducer from './slice/requestUnlockAccountSlice';
import allInvitationReducer from './slice/getAllInvitationSlice';
import getAllExternalUserReducer from './slice/getAllExternalUserSlice';
import getAllCompaniesReducer from './slice/getAllCompaniesSlice';
import getAllFilesSentByAdminReducer from './slice/getAllFilesSentByAdminSlice';
import getAllFAQReducer from './slice/getAllFAQSlice';
import getAllExternalUsersDocumentReducer from './slice/getAllExternalUsersDocumentSlice';
import getAllNotificationReducer from './slice/getAllNotificationSlice';

const store = configureStore({
  reducer: {
    demoCount: demoCountReducer,
    user: userReducer,
    login:loginReducer,
    signUp:signUpReduer,
    reqForgotPassLink: reqforgotPasswordLinkReducer,
    forgotPassword: forgotPasswordReducer,
    unlockAccount: UnlockAccountReducer,
    getAllInvitationData : allInvitationReducer,
    getAllExternalUserData: getAllExternalUserReducer,
    getAllCompaniesData:getAllCompaniesReducer,
    getAllFilesSentByAdmin:getAllFilesSentByAdminReducer,
    getAllFAQ: getAllFAQReducer,
    getAllExternalUsersDocument: getAllExternalUsersDocumentReducer,
    getAllNotificationData: getAllNotificationReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, any, UnknownAction>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store;