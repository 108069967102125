import { Box, Button, Container, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Controller, useForm } from "react-hook-form";
import { getManageUsersAccess, rejectAccountAccess, unlockAccountAccess } from "../../resources/api-constants";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

type FormData = {
    search: string;
};

const ManageUsersAccess = () => {

    const { control, watch, reset } = useForm<FormData>({
        defaultValues: {
            search: '',
        },
    });
    const [results, setResults] = useState([]);
    const [allManageUsersAccessData, setAllManageUsersAccessData] = useState<any>({});
    const searchValue = watch('search');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const getAllManageUsers = (pageNumber?: number | string, numberOfRows?: number | string, searchValue?: string) => {
        const payload = {
            page: pageNumber,
            page_size: numberOfRows,
            user_name: searchValue || '',
        };
        getManageUsersAccess(payload)
            .then(res => {
                setAllManageUsersAccessData(res?.data);
                setResults(res?.data?.results);
            })
            .catch(error => {
                console.log("🚀 ~ useEffect ~ error: pending api", error)
            })
    }

    const clearFilter = () => {
        reset();
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0 + 1);
    };

    const handleUnlockAccount = (id: number) => {
        const payload = {
            id: id
        }
        Swal.fire({
            text: 'Are you sure you want to unlock account?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer: 'custom-html-container',
            }
        }).then(result => {
            if (result.isConfirmed) {
                unlockAccountAccess(payload)
                    .then(res => {

                        getAllManageUsers(page, rowsPerPage, searchValue);

                        Swal.fire({
                            text: res?.data?.status || "User account has been successfully unlocked.",
                            icon: "success",
                            customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                htmlContainer: 'custom-html-container',
                            }
                        })
                    })
                    .catch(error => {
                        Swal.fire({
                            text: error.response.data.error || "Unable to unlock user account",
                            icon: "error",
                            customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                htmlContainer: 'custom-html-container',
                            }
                        })
                    })
            }
        })


    };


    const handleRejectAccount = (id: number) => {

        const payload = {
            id: id
        }
        Swal.fire({
            text: 'Are you sure you want to reject the account unlock request?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer: 'custom-html-container',
            }
        }).then(result => {
            if (result.isConfirmed) {
                rejectAccountAccess(payload)
                    .then(res => {

                        getAllManageUsers(page, rowsPerPage, searchValue);

                        Swal.fire({
                            text: res?.data?.status || "User unlock request has been rejected.",
                            icon: "success",
                            customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                htmlContainer: 'custom-html-container',
                            }
                        })
                    })
                    .catch(error => {
                        Swal.fire({
                            text: error.response.data.error || "Unable to reject user account request",
                            icon: "error",
                            customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                htmlContainer: 'custom-html-container',
                            }
                        })
                    })
            }
        })

    };

    useEffect(() => {
        setPage(1);
        getAllManageUsers(1, rowsPerPage, searchValue);
    }, [searchValue]);

    useEffect(() => {
        getAllManageUsers(page, rowsPerPage, searchValue);
    }, [page, rowsPerPage]);

    return (
        <>
            <Container component="main" sx={{ padding: '0px', marginLeft: '15px', marginTop: '15px' }} maxWidth='xl'>
                <Stack spacing={2}>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Typography variant='h4' sx={{ fontSize: '34px' }}>
                            List of Locked Accounts
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack spacing={2} mt={2}>

                            <Stack direction='row' spacing={2} alignItems={"center"}>

                                {/* Search By User Name */}
                                <Controller
                                    name="search"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Search User Name/Email"
                                            variant="outlined"
                                            sx={{ width: '35%' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                <Button
                                    variant="contained"
                                    type='button'
                                    onClick={clearFilter}
                                    disableRipple
                                    sx={{
                                        '&:hover': {
                                            bgcolor: '#FDB913',
                                            color: '#000000',
                                        },
                                        bgcolor: '#0073AE',
                                        fontSize: '14px',
                                        width: '15%',
                                        marginY: '15px !important'
                                    }}
                                >
                                    Clear Search
                                </Button>

                            </Stack>

                            <TableContainer component={Paper} >
                                <Table aria-label="simple table">
                                    <TableHead >
                                        <TableRow >
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>User Name</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>User Email</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {results?.length ? results?.map((account: any) => (

                                            <TableRow key={account?.id} >
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{account?.user?.full_name}</Typography></TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{account?.user?.email}</Typography></TableCell>

                                                <TableCell sx={{ fontSize: '12px' }} colSpan={9}>

                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                                        {
                                                            account?.lock_status === 'PENDING' ?
                                                                <Button
                                                                    variant="text"
                                                                    type='button'
                                                                    size='small'
                                                                    sx={{
                                                                        color: '#cb2c30', marginRight: '10px', fontSize: '11px', '&:hover': {
                                                                            // color: '#FDB913',
                                                                            textDecoration: 'underline'
                                                                        },
                                                                    }}
                                                                    onClick={() => handleRejectAccount(account?.id)}
                                                                    disableRipple
                                                                >
                                                                    Reject Request
                                                                </Button>
                                                                : <Typography sx={{ fontSize: '11px', color: '#cb2c30', textAlign: 'center', marginLeft: '6px !important', marginRight: '19px !important', '&:hover': { cursor: 'no-drop' } }} >Request Rejected</Typography>
                                                            // (
                                                            //     <Button
                                                            //         variant="outlined"
                                                            //         type='button'
                                                            //         size='small'
                                                            //         sx={{
                                                            //             py: 1,
                                                            //             px: 5,
                                                            //             fontSize: '11px',
                                                            //             fontWeight: 'bold',
                                                            //             borderColor: '#cb2c30',
                                                            //             color: '#cb2c30',
                                                            //             '&:hover': {
                                                            //                 color: '#FDB913',
                                                            //                 borderColor: '#FDB913',
                                                            //             }
                                                            //         }}
                                                            //     onClick={() => handleRejectAccount(account?.id)}
                                                            //     >
                                                            //         Reject Request
                                                            //     </Button>
                                                            // ) :
                                                            // (
                                                            //     <Button
                                                            //         variant="outlined"
                                                            //         type='button'
                                                            //         size='small'
                                                            //         disabled
                                                            //         sx={{
                                                            //             py: 1,
                                                            //             px: 4,
                                                            //             fontSize: '11px',
                                                            //             fontWeight: 'bold',
                                                            //             borderColor: '#cb2c30',
                                                            //             color: '#cb2c30',
                                                            //             cursor: 'no-drop',
                                                            //             '&:hover':{
                                                            //                 cursor: 'no-drop',
                                                            //             },
                                                            //             '&.Mui-disabled': {
                                                            //                 borderColor: '#cb2c30',
                                                            //                 color: '#cb2c30',
                                                            //                 pointerEvents: 'all'
                                                            //             }
                                                            //         }}
                                                            //     >
                                                            //         Request Rejected
                                                            //     </Button>
                                                            // )

                                                        }


                                                        <Button
                                                            variant="outlined"
                                                            type='button'
                                                            size='small'
                                                            sx={{
                                                                py: 1,
                                                                px: 5,
                                                                fontSize: '11px',
                                                                fontWeight: 'bold',
                                                                borderColor: '#FFBB00',
                                                                color: '#000000',
                                                                '&:hover': {
                                                                color: '#ffffff',
                                                                borderColor:'#FDB913',
                                                                bgcolor:'#0073ae',
                                                                }
                                                            }}
                                                            onClick={() => handleUnlockAccount(account?.id)}
                                                        >
                                                            Unlock
                                                        </Button>

                                                    </Stack>
                                                </TableCell>
                                            </TableRow>

                                        )) :
                                            <TableRow>
                                                <TableCell>Data Not Found</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={8}
                                                count={allManageUsersAccessData?.count || 0}
                                                rowsPerPage={allManageUsersAccessData?.page_size || 5}
                                                page={allManageUsersAccessData?.current_page - 1 || 0}
                                                slotProps={{
                                                    select: {
                                                        inputProps: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                    },
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default ManageUsersAccess;