import React, { useEffect,useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm,SubmitHandler,Controller, Control } from 'react-hook-form';
import { Box,Stack,Autocomplete } from '@mui/material';
import { getApps,editMasterApps } from '../../resources/api-constants';
import Swal from 'sweetalert2';
import { getAllCompaniesArray } from '../../resources/api-constants';
import { successResult } from '../../store/slice/getAllExternalUserSlice';
import { useAppDispatch } from '../../store/store';


interface IFormInput {
    app:[];
  }

    type App = {
   id:string;
    name:string;
}[]

const EditApp = (props: { onOpen: any; onClose: any; userId:any;selectedApps:any; companyId :string | null; getAllMasterUsers:(pageNumber: any,numberOfRows: any) =>void }) => {
    const {onOpen, onClose, getAllMasterUsers,companyId, userId,selectedApps} = props;
    const dispatch = useAppDispatch();
    const [appMenu, setAppMenu] = useState<App>([])
    
    const { register, handleSubmit, formState: { errors }, setError, reset, control } = useForm<IFormInput>({
        defaultValues:{
            app:selectedApps ? selectedApps : appMenu,   
        }
    });

    useEffect(()=>{
        getApps()
        .then(res =>{
            const appsArray = res.data.results.map((item:any) =>( {
                id: item.id,
                name: item.name,
              }))
            setAppMenu(appsArray)
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    

    const onSubmit: SubmitHandler<IFormInput> = (data:IFormInput) => {
    //@ts-ignore
    const appArray = data.app.map(item => item.id)

    const payload = {
        apps:appArray,
    }
    
    editMasterApps(payload,userId)
        .then((res) => {
                closeDialog();
            Swal.fire({
                text: "Apps has been sucessfully updated.",
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              }).then((result) => {
                if (result.isConfirmed) {
                    getAllMasterUsers(1,5)       
                }
              });
        })
        .catch(error => {
            closeDialog();
            Swal.fire({
                text: error.response.data.error,
                icon: "error",
                customClass:{
                  popup:'custom-swal',
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                }
              });
        })
       
        
    }

    const closeDialog = () =>{
        reset();
        onClose()
    }
    
  return (
    <Dialog
        open={onOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          error:errors,
        }}
      >
        <DialogTitle>Edit App</DialogTitle>
        <DialogContent>
        <Box
        >
        <Stack direction='row' justifyContent='space-between' m={1} alignItems='center' width='100%'>
        
            <Controller
                name='app'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={appMenu}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={field.value || []}
                    onChange={(_, value) => field.onChange(value)}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label='Select Apps'
                      error={!!errors.app}
                      helperText={errors.app ? errors.app.message : ''}
                      />
                    )}
                    sx={{ width: '50%', margin:'auto' }}
                    />
                )}
            />
        </Stack>
      
        
        
      </Box>
      
         
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} sx={{
            color: '#0073AE',
            '&:hover': {
              color: '#FDB913',
              
            }
          }}>Cancel</Button>
          {/* <Button type="submit" variant='contained'>Invite</Button> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              bgcolor: '#0073AE',
              '&:hover': {
                color: '#000000',
                bgcolor:'#FDB913',
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default React.memo(EditApp)