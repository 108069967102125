import React, { useEffect,useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm,SubmitHandler,Controller, Control } from 'react-hook-form';
import { Box, MenuItem,Menu, Stack,InputLabel,Autocomplete } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getApps, sendInvitation } from '../../resources/api-constants';
import Swal from 'sweetalert2';
import { getAllInvitations } from '../../resources/api-constants';
import { success } from '../../store/slice/getAllInvitationSlice';
import { useAppDispatch } from '../../store/store';
type Roles = {
    value:string;
    label:string;
}

interface IFormInput {
    firstName: string;
    lastName: string;
    email: string;
    role:string;
    app:[];
  }

  const roles = [
    {
      value: 'Admin',
      label: 'admin',
    },
    {
      value: 'Master',
      label: 'master',
    },
   
  ];

  type App = {
   id:string;
    name:string;
}[]

const demoArray = [
    {id:'1', name:'abc'},
    {id:'2', name:'ddd'},
    {id:'3', name:'fff'},
    {id:'4', name:'ccc'},
    
]

const InviteNewUser = (props: { onOpen: any; onClose: any; }) => {
    const {onOpen, onClose} = props;

    const { register, handleSubmit, formState: { errors }, setError, reset, control } = useForm<IFormInput>({
        defaultValues:{
            firstName:'',
            lastName:'',
            email:'',
            role:'',
            app:[],        
        }
    });

    const dispatch = useAppDispatch();
    
    const [role, setRole] = useState('');  

    const handleSetRole = (event: SelectChangeEvent) => {
        setRole(event.target.value);
    };
    const [appMenu, setAppMenu] = useState<App>([])
    const [apps, setApps] = useState('');  

    const handleSetApp = (event: SelectChangeEvent) => {
        setApps(event.target.value);
    };

    useEffect(()=>{
        getApps()
        .then(res =>{
            const appsArray = res.data.results.map((item:any) =>( {
                id: item.id,
                name: item.name,
              }))
            setAppMenu(appsArray)
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    const onSubmit: SubmitHandler<IFormInput> = (data:IFormInput) => {
    //@ts-ignore
    const appArray = data.app.map(item => item.id)

    const payload = {
        first_name:data.firstName,
        last_name:data.lastName,
        role:data.role,
        email:data.email.toLowerCase(),
        apps:appArray,
    }

        sendInvitation(payload)
        .then((res) => {
            if(res.status == 201){
                closeDialog();
            }
            Swal.fire({
                text: "Invitation has been sent successfully.",
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              }).then((result) => {
                if (result.isConfirmed) {
                  const payload = {
                    page:1,
                    page_size:5,
                }
                  getAllInvitations(payload)
                  .then(res => {
                      //@ts-ignore
                      dispatch(success(res))                      
                  })
                  .catch(error => {
                      console.log("🚀 ~ useEffect ~ res:", error)
                  })
                }
              });
        })
        .catch(error => {
            closeDialog();
            Swal.fire({
                text: error.response.data.error,
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
        })
       
        
    }

    const closeDialog = () =>{
        reset();
        onClose()
    }

  return (
    <Dialog
        open={onOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          error:errors,
        }}
      >
        <DialogTitle sx={{pb: '0px !important'}}><b>Invite New User</b></DialogTitle>
        <DialogContent>
        <Box
            // component="form"
            // onSubmit={handleSubmit(onSubmit)}
            // noValidate
        >
        <Stack direction='row' justifyContent='space-between' mb={1} alignItems='center' width='100%'>
            <TextField
                variant="outlined"
                margin="normal"
                id="firstName"
                label="First Name"
                autoComplete="firstName"
                autoFocus
                size="small"
                sx={{
                    width:'48%',
                }}
                {...register('firstName', {
                  required: 'First Name is required',
                  pattern: {
                    value: /^(?! )[a-zA-Z ]*$/,
                    message: 'Special characters, numbers and only space are not allowed',
                  },
          })}
          error={!!errors.firstName}
          helperText={errors.firstName ? errors.firstName.message : ''}
        />
        <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                autoComplete="lastName"
                size="small"
                sx={{
                    width:'48%',
                   
                }}
                {...register('lastName', {
                  required: 'Last Name is required',
                  pattern: {
                    value: /^(?! )[a-zA-Z ]*$/,
                    message: 'Special characters, numbers and only space are not allowed',
                  },
          })}
          error={!!errors.lastName}
          helperText={errors.lastName ? errors.lastName.message : ''}
        />
        </Stack>
        <Box width='100%'>
        <TextField
            variant="outlined"
            // margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            size="small"
            sx={{
                width:'101%',
            }}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Enter a valid Email address',
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />
          </Box>

          <Stack direction='row' justifyContent='space-between'  alignItems='center' mt={2} width='100%'>

      <TextField
          id="role"
          select
          label="Role"
          sx={{
            width:'48%'
          }}
        //   defaultValue="EUR"
            error={!!errors.role}
            helperText={errors.role ? errors.role.message : ''}
          variant="outlined"
          size="small"
          {...register('role', {
            required: 'Role is required',
          })}
        >
          {roles?.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>

    <Controller
      name='app'
      control={control}
      rules={{ required: 'This field is required' }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          options={appMenu}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={field.value || []}
          onChange={(_, value) => field.onChange(value)}
          size="small"
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label='Select Apps' placeholder="Select Apps"
            error={!!errors.app}
            helperText={errors.app ? errors.app.message : ''}
            />
          )}
          sx={{ width: '48%' }}
        />
      )}
    />

        {/* <Autocomplete
        // {...field}
        multiple
        id="app"
        options={appMenu}
        //getOptionLabel={(option) => option}
        // defaultValue={[top100Films[13]]}
        filterSelectedOptions
        
        size="small"
        sx={{
            width:'48%'
          }}
          {...register('app', {
              required: 'App is required',
          })}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Apps"
            //placeholder="Apps"
            error={!!errors.app}
            helperText={errors.app ? errors.app.message : ''}
            // variant="outlined"
            
          />
        )}
      /> */}

        {/* <TextField
          id="app"
          select
          label="Select App"
          sx={{
            width:'48%'
          }}
        //   defaultValue="EUR"
            error={!!errors.app}
            helperText={errors.app ? errors.app.message : ''}
          variant="outlined"
          size="small"
          {...register('app', {
            required: 'App is required',
          })}
        >
          {appMenu?.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField> */}

      {/* <Select
                // sx={SignUpStyles.dropDown}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={roles}
              label="Role"
              size="small"
              onChange={handleSetRole}
              fullWidth
            >
                <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
                {
                //   countryPhoneCodes.map((codes,index) => <MenuItem value={codes.code} key={index}>{codes.code}</MenuItem>)
                }
            </Select>    */}
            {/* <Select
                // sx={SignUpStyles.dropDown}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={apps}
              label="Apps"
              onChange={handleSetApp}
              fullWidth
              size="small"
            >
                 <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
                {
                //   countryPhoneCodes.map((codes,index) => <MenuItem value={codes.code} key={index}>{codes.code}</MenuItem>)
                }
            </Select> */}
      </Stack>
      </Box>
      
         
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}  sx={{ '&:hover': {
              color: '#FDB913',
            },
            color: '#0073AE'
            }}>Cancel</Button>
          {/* <Button type="submit" variant='contained'>Invite</Button> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ '&:hover': {
              bgcolor: '#FDB913',
              color:'#000000'
            },
            bgcolor: '#0073AE'
          }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default React.memo(InviteNewUser)