import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Stack, Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Typography } from "@mui/material";
import NotificationCard from "../../components/NotificationCard";
import { useEffect, useState } from "react";
import { getAllNotifications } from "../../resources/api-constants";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { NotificationSuccess, updatePage, updateRowsPerPage } from "../../store/slice/getAllNotificationSlice";
import NotificationDetail from "./NotificationDetail";
import Swal from "sweetalert2";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ROUTES } from "../../resources/routes-constants";

const Notifications = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const { allNotificationData, results, page, rowsPerPage } = useAppSelector<any>(state => state.getAllNotificationData)

    const getAllNotificationsHandle = (pageNumber?: number, numberOfRows?: number) => {
        const payload = {
            page: pageNumber ? pageNumber : page,
            page_size: numberOfRows ? numberOfRows : rowsPerPage,
        }

        getAllNotifications(payload)
            .then(res => {
                //@ts-ignore
                dispatch(NotificationSuccess(res));
                // console.log(res);
            })
            .catch(error => {
                console.log("🚀 ~ useEffect ~ error: pending api", error)
            })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(updatePage(newPage + 1));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateRowsPerPage(parseInt(event.target.value, 10)));
        dispatch(updatePage(1))
    };

    const handleNotificationInfo = (event: any, file_id: string, reupload_file_id: string, notification_type: string) => {
        console.log(notification_type);
        if (event?.target?.tagName !== 'BUTTON') {

            if (notification_type === 'upload' || notification_type === 'reminder') {

                if (file_id) {
                    const params = { fileId: file_id };
                    navigate({
                        pathname: `${ROUTES.FILE_TRANSFER}`,
                        search: `?${createSearchParams(params)}`,
                    });
                } else {
                    Swal.fire({
                        text: "No document with this name found.",
                        icon: "error",
                        customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            htmlContainer: 'custom-html-container',
                        }
                    })
                }

            } 

            if (notification_type === 'reupload') {
                if (reupload_file_id) {
                    const params = { reuploadFileId: reupload_file_id };
                    navigate({
                        pathname: `${ROUTES.DOCUMENT_MANAGEMENT}`,
                        search: `?${createSearchParams(params)}`,
                    });
                } else {
                    Swal.fire({
                        text: "No document with this name found.",
                        icon: "error",
                        customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            htmlContainer: 'custom-html-container',
                        }
                    })
                }
            }
        }
    };

    useEffect(() => {
        dispatch(updatePage(1));
        dispatch(updateRowsPerPage(5));
        getAllNotificationsHandle(1, 5);
    }, [])

    useEffect(() => {
        getAllNotificationsHandle();
    }, [page, rowsPerPage])

    return (
        <>
            <Container component="main" sx={{ padding: '0px', marginTop: '15px', marginLeft: '15px' }} maxWidth='xl'>
                <Stack spacing={2}>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Typography variant='h4' sx={{ fontSize: '34px' }}>
                                Notifications
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Table aria-label="simple table">
                            <TableBody>
                                {results.length ? results.map((noti: any) => (
                                    <TableRow key={noti.id}>
                                        <TableCell onClick={(event) => handleNotificationInfo(event, noti?.file_id, noti?.reupload_file_id, noti?.notification_type)} sx={{ cursor: 'pointer', padding: '0px', paddingBottom: '5px', borderBottom: 'none' }}>
                                            <NotificationCard CustomDialogBox={NotificationDetail} getAllNotificationsHandle={getAllNotificationsHandle} id={noti?.id} title={noti?.title} description={noti?.description} date={noti?.created_at} isMarkedRead={noti?.is_read} />
                                        </TableCell>

                                        {/* <TableCell sx={{ cursor: 'pointer', padding: '0px', paddingBottom: '5px', borderBottom: 'none' }}>
                                            <NotificationCard CustomDialogBox={NotificationDetail} getAllNotificationsHandle={getAllNotificationsHandle} id={noti?.id} title={noti?.title} description={noti?.description} date={noti?.created_at} isMarkedRead={noti?.is_read} />
                                        </TableCell> */}

                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={7} sx={{ textAlign: 'center' }}>Message Not Found</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={7}
                                        count={allNotificationData?.count || 0}
                                        rowsPerPage={allNotificationData?.page_size || 5}
                                        page={allNotificationData?.current_page - 1 || 0}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Box>
                </Stack>
            </Container>


        </>
    )
}

export default Notifications;