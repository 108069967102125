export const SignUpStyles = {
  container:  {
        width: "520px",
        height: '409px'
  },
  boxContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  cardContainer:{ width: '100%', boxShadow: 15 },
  cardBox:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  formContainer:{ mt: 1,
    maxWidth: '80%',
 },

 dropDown:{ width: '27%', marginTop:'16px', marginLeft:'0px' },
 signUpButton:{ mt: 3, mb: 2,
    bgcolor:'#0073AE',
    '&:hover': {
      bgcolor: '#FDB913',
      color:'#000000'
    },
   },
   

}